import React, { useMemo } from "react";

interface PageContainerWrapProps {
  className?: string;
  children?: any;
  title?: string;
  inputWrap?: boolean;
}

const PageContainerWrap = ({
  className,
  children,
  title,
  inputWrap,
}: PageContainerWrapProps) => {
  return (
    <div className="p-4 text-left border border-border-black mb-8">
      <div className={`mb-12 text-xl font-bold`}>{title}</div>
      <div className={`${className} ${inputWrap ? "max-w-[640px]" : ""}`}>
        {children}
      </div>
    </div>
  );
};

export default PageContainerWrap;
