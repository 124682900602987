import React from "react";
import { urlToHttpOptions } from "url";

interface FormRowProps {
  marginR?: boolean;
  marginT?: boolean;
  className?: string;

  readOnly?: boolean;
  onChange?: (e: any) => void;

  inputOnText?: string;

  input?: boolean;
  value?: string;

  select?: boolean;
  options?: any[];

  textArea?: boolean;
  row?: number;

  checkBox?: boolean;

  type?: string;
  placeholder?: string;

  size?: "XS" | "S" | "M" | "L" | "XL";
}

const FormRow = ({
  className,
  onChange,
  readOnly,
  inputOnText,
  size,
  value,

  input,
  select,
  options,
  textArea,
  row,
  checkBox,
  marginR,
  marginT,
  type,
  placeholder,
}: FormRowProps) => {
  const XS = size === "XS";
  const S = size === "S";
  const M = size === "M";
  const L = size === "L";
  const XL = size === "XL";
  return (
    <div className="relative">
      {inputOnText ? <div className="absolute right-2 top-[7px]">{inputOnText}</div> : ""}
      {input ? (
        <input
          type={type}
          value={value}
          readOnly={readOnly}
          placeholder={placeholder}
          onChange={onChange}
          className={`
        ${className}
        ${inputOnText ? "pr-4" : ""}
        ${marginR ? "mr-8" : ""}
        ${marginT ? "mt-4" : ""}
      ${
        XS
          ? ""
          : S
          ? "w-[192px]"
          : M
          ? "w-[416px]"
          : L
          ? "w-[640px]"
          : XL
          ? ""
          : ""
      }`}
        />
      ) : select ? (
        <select
          value={value}
          onChange={onChange}
          className={`
        
        ${className}
        ${marginR ? "mr-8" : ""}
        ${marginT ? "mt-4" : ""}
      ${
        XS
          ? ""
          : S
          ? "w-[192px]"
          : M
          ? "w-[416px]"
          : L
          ? "w-[640px]"
          : XL
          ? ""
          : ""
      }`}
        >
          <option className="optionDefaultValue" value="">
            {placeholder}
          </option>
          {options?.map((value) => {
            return (
              <option key={value} value={value}>
                {value}
              </option>
            );
          })}
        </select>
      ) : textArea ? (
        <textarea
          rows={row}
          placeholder={placeholder}
          onChange={onChange}
          className={`
        ${className}
        ${marginR ? "mr-8" : ""}
        ${marginT ? "mt-4" : ""}
      ${
        XS
          ? ""
          : S
          ? "w-[192px]"
          : M
          ? "w-[416px]"
          : L
          ? "w-[640px]"
          : XL
          ? ""
          : ""
      }`}
        ></textarea>
      ) : (
        ""
      )}
    </div>
  );
};

export default FormRow;
