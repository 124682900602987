import React from 'react';

interface checkBoxProps {
    onChange?: (e: any) => void;

    title?: string;
    defaultChecked?: any;
    value?: string;
    checked?: any;
    id?:any
    imageUrl?: any;
    typesModal?:string;
}

const CheckBoxDefault = ({
    defaultChecked,
    value,
    onChange,
    id,
    checked,
    title,
    imageUrl,
}:checkBoxProps) => {


    return (
        <div className={`w-[213px] flex items-center mb-4`}>
        <input
          id={id}
          defaultChecked={defaultChecked}
          value={value}
          onChange={onChange}
          checked={checked}
          type="checkbox"
        />
        {imageUrl ? <img className="w-6 h-6 mx-1" src={imageUrl} alt="" /> : "" }
        <div className={`${imageUrl ? "" : "ml-1"}`}>
        {title}
        </div>
      </div>
    );
};

export default CheckBoxDefault;