import React from 'react';
import DaumPostcodeEmbed, { useDaumPostcodePopup } from 'react-daum-postcode';
import ButtonDefault from './HTMLTagCompo/ButtonDefault';

const DaumAddressSearch = ({setGoogleMapInfo}:any) => {
    const CURRENT_URL =
		'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
    const open = useDaumPostcodePopup(CURRENT_URL);
    const handleComplete = (data:any) => {
        let fullAddress = data.address;
        let extraAddress = '';
    
        if (data.addressType === 'R') {
          if (data.bname !== '') {
            extraAddress += data.bname;
          }
          if (data.buildingName !== '') {
            extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
          }
          fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }
        setGoogleMapInfo(data);
    }
    const handleClick = () => {
        open({ onComplete: handleComplete });
      };
    return (
        <div>
            <ButtonDefault
                onClick={handleClick}
                title="주소검색"
                className="mb-1 text-center text-pure-white rounded-lg bg-light-green"
              />
        </div>
    );
};

export default DaumAddressSearch;
