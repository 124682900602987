import React, { useCallback, useEffect, useState } from "react";

import TableDefault from "components/HTMLTagCompo/TableDefault";
import PageContainerWrap from "components/page/PageContainerWrap";
import PageHeaderTitle from "components/page/PageHeaderTitle";
import { POLICY_COLUMNS } from "components/page/tableData";
import { requestToMustOutdoor } from "utils/requestToMustoutdoor";
import ButtonDefault from "components/HTMLTagCompo/ButtonDefault";

const CancellationPolicy = () => {
  const [policy, setPolicy] = useState<any>([]);

  const fetchPolicy = useCallback(() => {
    requestToMustOutdoor({
      method: "GET",
      url: "/api/v3/policy/fetch",
    }).then((res) => {
      const data = res.data.data;
      setPolicy(data?.content);
      return data;
    });
  }, []);

  useEffect(() => {
    fetchPolicy();
  }, []);

  console.log(policy);

  return (
    <div>
      <PageHeaderTitle title="Cancellation Policy" >
        <ButtonDefault title="Add Policy" className="bg-light-green" />
      </PageHeaderTitle>
      <PageContainerWrap title="Cancellation Policy">
        <TableDefault COLUMNS={POLICY_COLUMNS}>
          {policy?.map((item: any, idx: number) => {
            const bizType = item.bizType;
            const name = item.name;
            const updatedAt = item.updatedAt;
            return (
              <tr key={idx}>
                <td>{bizType}</td>
                <td>{name}</td>
                <td>{updatedAt !== null ? updatedAt : "-"}</td>
                <td>
                  <div className="flex min-w-[63px] items-center justify-between">
                    <img
                      className="w-5 h-5 cursor-pointer"
                      alt=""
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/edit-user.svg"
                    />
                    /
                    <img
                      className="w-5 h-5 cursor-pointer"
                      alt=""
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/delete-trash.svg"
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </TableDefault>
      </PageContainerWrap>
    </div>
  );
};

export default CancellationPolicy;
