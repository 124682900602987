import React from "react";
import { Route, Routes } from "react-router-dom";

import Layout from "pages/Layout";
import MainLogin from "pages/MainLogin";
import SignUp from "pages/SignUp";
import Main from "pages/Main";
import ItemDetail from "components/itemDetail";
import PrivateGroundListings from "components/adminTab/privateGroundlistings";
import DashBoard from "components/adminTab/dashboard";
import ManageHost from "components/adminTab/manageHost";
import ManageUsers from "components/adminTab/manageUsers";
import ManageAdmin from "components/adminTab/manageAdmin";
import PublicGroundListings from "components/adminTab/publicGroundlistings";
import AccommodationListing from "components/adminTab/accommodationListing";
import ActivityListing from "components/adminTab/activityListing";
import BookingLog from "components/adminTab/bookingLog";
import AutomaticNotification from "components/adminTab/automaticNotification";
import DestinationSearch from "components/adminTab/destinationSearch";
import EmailSendSetting from "components/adminTab/emailSendSetting";
import EmailTemplate from "components/adminTab/emailTemplate";
import ExchangeRate from "components/adminTab/exchangeRate";
import FAQSetting from "components/adminTab/FAQSetting";
import MainSlider from "components/adminTab/mainSlider";
import ManualNotification from "components/adminTab/manualNotification";
import PopularDestination from "components/adminTab/popularDestination";
import Reviews from "components/adminTab/reviews.";
import ThemeSetting from "components/adminTab/themeSetting";
import Payout from "components/adminTab/payout";
import AddItem from "components/addItem";
import Payment from "components/adminTab/payment";
import UserInfo from "components/userInfo";
import PgIcon from "components/adminTab/pgIcon";
import AcIcon from "components/adminTab/acIcon";
import AtIcon from "components/adminTab/atIcon";
import CommonIcon from "components/adminTab/commonIcon";
import BookingDetail from "components/bookingDetail";
import TypesManage from "components/adminTab/typesManage";
import LocationThemeManage from "components/adminTab/locationThemeManage";
import CreateIcons from "components/CreateIcons";
import CancellationPolicy from "components/adminTab/cancellationPolicy";
import AddPopularDestination from "components/page/addPopularDestination";

function App() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<MainLogin />} />
        <Route path="sign-up" element={<SignUp />} />

        <Route path="main" element={<DashBoard />} />
        <Route path="manage/admin" element={<ManageAdmin />} />
        <Route path="manage/user" element={<ManageUsers />} />
        <Route path="manage/host" element={<ManageHost />} />
        <Route path="manage/user-info" element={<UserInfo />} />
        <Route path="private" element={<PrivateGroundListings />} />
        <Route path="public" element={<PublicGroundListings />} />
        <Route path="accommodation" element={<AccommodationListing />} />
        <Route path="activity" element={<ActivityListing />} />
        <Route path="policy" element={<CancellationPolicy />} />
        <Route path="payment" element={<Payment />} />
        <Route path="booking" element={<BookingLog />} />
        <Route path="payout" element={<Payout />} />
        <Route path="destination" element={<DestinationSearch />} />
        <Route path="slider" element={<MainSlider />} />
        <Route path="theme" element={<ThemeSetting />} />
        <Route path="popular" element={<PopularDestination />} />
        <Route path="popular/add" element={<AddPopularDestination />} />

        <Route path="noti/manual" element={<ManualNotification />} />
        <Route path="noti/auto" element={<AutomaticNotification />} />
        <Route path="email/template" element={<EmailTemplate />} />
        <Route path="email/setting" element={<EmailSendSetting />} />
        <Route path="review" element={<Reviews />} />
        <Route path="exchange" element={<ExchangeRate />} />
        <Route path="faq" element={<FAQSetting />} />
        <Route path="icon/type" element={<TypesManage />} />
        <Route path="icon/theme" element={<LocationThemeManage />} />

        <Route path="icon/pg" element={<PgIcon />} />
        <Route path="icon/ac" element={<AcIcon />} />
        <Route path="icon/at" element={<AtIcon />} />
        <Route path="icon/common" element={<CommonIcon />} />

        <Route path="icon/add" element={<CreateIcons />} />

        <Route path="add" element={<AddItem/>} />
        <Route path="detail/*" element={<ItemDetail />} />

        <Route path="booking/details" element={<BookingDetail />} />
        <Route path="add" element={<AddItem/>} />
        <Route path="detail/*" element={<ItemDetail />} />
      </Route>
    </Routes>
  );
}

export default App;
