import TableDefault from "components/HTMLTagCompo/TableDefault";
import React from "react";

import PageContainerWrap from "components/page/PageContainerWrap";
import PageHeaderTitle from "components/page/PageHeaderTitle";
import { PAYMENT_COLUMNS } from "components/page/tableData";

const Payment = () => {
  return (
    <div>
      <PageHeaderTitle title="Payment Log" />
      <PageContainerWrap title="Payment Log Information">
        <TableDefault COLUMNS={PAYMENT_COLUMNS}></TableDefault>
      </PageContainerWrap>
    </div>
  );
};

export default Payment;
