import React, { useEffect, useState } from "react";

import Header from "components/Header";
import { Outlet } from "react-router-dom";
import SideTab from "components/SideTab";

const Layout = () => {
  const [login, setLogin] = useState(false);

  const userInfo = JSON.parse(localStorage.getItem('user') as string);
  
  const isLogin = userInfo?.isLogin

  useEffect(() => {
    isLogin ? setLogin(true) : setLogin(false);
  },[isLogin])

  return (
    <div>
      <Header />
      <div className="flex">
        {login ? <SideTab /> : ""}
        <div className="px-4 flex-1">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
