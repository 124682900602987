import React, { useCallback, useEffect, useRef, useState } from "react";
import PageContainerWrap from "./PageContainerWrap";
import PageHeaderTitle from "./PageHeaderTitle";
import FormTitle from "components/InputForm/FormTitle";
import FormRow from "components/InputForm/FormRow";
import { requestToMustOutdoor } from "utils/requestToMustoutdoor";
import ButtonDefault from "components/HTMLTagCompo/ButtonDefault";

const AddPopularDestination = () => {
  const [imageUrl, setImageUrl] = useState("");

  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");

  const { userId } = JSON.parse(localStorage.getItem("user") as any);

  // const getCode = useCallback(() => {
  //   requestToMustOutdoor({
  //     method: "GET",
  //     url:"/guest/main/common/code",
  //   })
  //   .then((res) => {
  //     const data = res.data.data;
  //     setCode(data);
  //     return data;
  //   })
  // },[])

  // useEffect(() => {
  //   getCode()
  // },[])

  // console.log(code)

  const fileInput = useRef<any>();

  const handleButtonClick = () => {
    fileInput?.current.click();
  };

  const HandleFeature = (e: any) => {
    let files = e.target.files[0];
    const fileSize = files.size;
    const data = {
      multipartFiles: files,
    };
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > 1024 ** 2 * 5) {
        return alert(`사이즈가 5mb 보다 큰 파일이 있습니다.`);
      }
    }

    requestToMustOutdoor
      .post("/guest/upload/file", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
      })
      .then((res) => {
        const result = res.data.data;
        const imgName = result[0];
        const imgUrl_ = `${process.env.REACT_APP_IMAGE_DEV_UPLOAD_URL}${imgName}`;
        setImageUrl(imgUrl_);
        return res.data.data;
      })
      .catch((err) => {});
  };

  const saveDestination = () => {
    if (
      name === "" ||
      desc === "" ||
      country === "" ||
      state === "" ||
      city === "" ||
      imageUrl === ""
    ) {
      alert("빈칸이 있습니다");
    } else {
      requestToMustOutdoor({
        method: "POST",
        url: "/api/v3/destination/register",
        data: {
          city: city,
          country: country,
          description: desc,
          imageUrl: imageUrl,
          languageCode: "KR",
          name: name,
          state: state,
          totalMemberId: Number(userId),
        },
      })
        .then((res) => {
          const data = res.data.data;
          alert("저장완료");
          return data;
        })
        .catch((err) => {
          alert("저장실패");
        });
    }
  };
  return (
    <div>
      <PageHeaderTitle title="Add Popular Destination" />
      <PageContainerWrap title="Select in destination search list">
        <FormTitle rowForm formTitle="Choose Destination">
          <div>
            <div>Country / Region</div>
            <FormRow
              onChange={(e) => {
                setCountry(e.target.value);
              }}
              size="S"
              input
              marginR
              className="mt-2"
            ></FormRow>
          </div>
          <div>
            <div>State</div>
            <FormRow
              onChange={(e) => {
                setState(e.target.value);
              }}
              size="S"
              input
              marginR
              className="mt-2"
            ></FormRow>
          </div>
          <div>
            <div>City</div>
            <FormRow
              onChange={(e) => {
                setCity(e.target.value);
              }}
              size="S"
              input
              className="mt-2"
            ></FormRow>
          </div>
        </FormTitle>
        <FormTitle rowForm formTitle="Destination Feature Image*">
          {imageUrl ? (
            <img src={imageUrl} alt="" className="w-96 h-96"></img>
          ) : (
            <div className="border border-input-border-color w-96 h-96 cursor-pointer ">
              <input
                onChange={(e) => {
                  HandleFeature(e);
                }}
                ref={fileInput}
                type="file"
                className="hidden"
              />
              <div
                onClick={() => {
                  handleButtonClick();
                }}
              >
                이미지 업로드
              </div>
            </div>
          )}
        </FormTitle>
        <FormTitle rowForm formTitle="info">
          <div className="flex flex-col">
            <div>
              <div>Name</div>
              <FormRow
                onChange={(e) => {
                  setName(e.target.value);
                }}
                size="M"
                input
                marginR
                className="mt-2"
              ></FormRow>
            </div>
            <div>
              <div>Desc</div>
              <FormRow
                size="L"
                onChange={(e) => {
                  setDesc(e.target.value);
                }}
                textArea
                marginR
                className="mt-2"
              ></FormRow>
            </div>
          </div>
        </FormTitle>
        <ButtonDefault
          onClick={() => {
            saveDestination();
          }}
          title="Save"
          className="bg-light-green"
        ></ButtonDefault>
      </PageContainerWrap>
    </div>
  );
};

export default AddPopularDestination;
