import ButtonDefault from "components/HTMLTagCompo/ButtonDefault";
import TableDefault from "components/HTMLTagCompo/TableDefault";
import PageHeaderTitle from "components/page/PageHeaderTitle";
import { TYPES_COLUMNS, USER_MANAGE } from "components/page/tableData";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { requestToMustOutdoor } from "utils/requestToMustoutdoor";

const TypesManage = () => {
  const navigate = useNavigate();
  const [tabNum, setTabNum] = useState(0);

  const [types, setTypes] = useState<any>([]);

  const tabTitle = [
    { id: 0, title: "Private Ground" },
    { id: 1, title: "Accommodation" },
    { id: 2, title: "Activity" },
  ];

  const fetchTypes = useCallback(() => {
    requestToMustOutdoor({
      method: "GET",
      url: "/api/v3/type/fetch",
    }).then((res) => {
      const data = res.data.data;
      setTypes(data?.content);
      return data;
    });
  }, []);

  useEffect(() => {
    fetchTypes();
  }, []);

  return (
    <div>
      <PageHeaderTitle title="Types Manage">
        <ButtonDefault
          onClick={() => {
            navigate("/icon/add", { state: { type: "TYPE" } });
          }}
          className="bg-light-green"
          title="Create Types"
        ></ButtonDefault>
      </PageHeaderTitle>
      <div
        className="border-b h-11 flex phone:whitespace-nowrap 
                  phone:overflow-x-scroll phone:noScroll"
      >
        {tabTitle?.map((item, idx) => {
          return (
            <div
              key={idx}
              onClick={() => {
                setTabNum(item.id);
              }}
              className={`
                  ${
                    item.id === tabNum
                      ? "bg-light-green text-pure-white"
                      : " text-input-text"
                  } px-4 items-center cursor-pointer flex focus:bg-light-green rounded-t-lg`}
            >
              {item.title}
            </div>
          );
        })}
      </div>
      {tabNum === 0 ? (
        <div className="p-4">
          <div className="flex justify-between mb-8">
            <div className="text-xl font-bold">List</div>
            <div>List</div>
          </div>
          <TableDefault COLUMNS={TYPES_COLUMNS}>
            {types?.map((item: any, idx: number) => {
              const id = item.id;
              const listingType = item.listingType;
              const title = item.title;
              const description = item.description;
              const updatedAt = item.updatedAt;
              const featured = item.featured;
              if (listingType === "PG") {
                return (
                  <tr key={idx}>
                    <td>{id}</td>
                    <td>{title}</td>
                    <td>{description}</td>
                    <td>{featured === true ? "YES" : "NO"}</td>
                    <td>{updatedAt !== null ? updatedAt : "-"}</td>
                    <td>
                      <div className="flex min-w-[63px] items-center justify-between">
                        <img
                          className="w-5 h-5 cursor-pointer"
                          alt=""
                          src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/edit-user.svg"
                        />
                        /
                        <img
                          className="w-5 h-5 cursor-pointer"
                          alt=""
                          src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/delete-trash.svg"
                        />
                      </div>
                    </td>
                  </tr>
                );
              }
            })}
          </TableDefault>
        </div>
      ) : tabNum === 1 ? (
        <div></div>
      ) : tabNum === 2 ? (
        <div></div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TypesManage;
