import ButtonDefault from "components/HTMLTagCompo/ButtonDefault";
import TableDefault from "components/HTMLTagCompo/TableDefault";
import PageContainerWrap from "components/page/PageContainerWrap";
import PageHeaderTitle from "components/page/PageHeaderTitle";
import { PRIVATE_COLUMNS, PUBLIC_COLUMNS } from "components/page/tableData";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChangeDate } from "utils/changeDate";
import { requestToMustOutdoor } from "utils/requestToMustoutdoor";

const PublicGroundListings = () => {
  const navigate = useNavigate();
  const [publicData, setPublicData] = useState([]);

  const fetchPublic = useCallback(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v3/public-ground/fetch`,
      data: { title: "", page: "", size: "", sort: "" },
    }).then((res) => {
      const data = res.data.data;
      setPublicData(data?.content);
      return data;
    });
  }, []);

  useEffect(() => {
    fetchPublic();
  }, []);
  return (
    <div>
      <PageHeaderTitle title="Admin Public Ground">
        <ButtonDefault onClick={() => {navigate("/add")}}
          className="bg-light-green"
          title="Add Public Ground"
        ></ButtonDefault>
      </PageHeaderTitle>
      <PageContainerWrap className="w-full" title="Listings">
        <TableDefault COLUMNS={PUBLIC_COLUMNS}>
          {publicData?.map((item: any, idx) => {
            const id = item.id;
            const title = item.title;
            const state = item.state;
            const views = item.views;
            const createdAt = ChangeDate(item.createdAt);
            const updatedAt = ChangeDate(item.updatedAt);

            return (
              <tr key={idx}>
                <td>{id}</td>
                <td>{title}</td>
                <td>{state}</td>
                <td>{views}</td>
                <td>{createdAt}</td>
                <td>{updatedAt}</td>
                <td>
                  <div className="flex min-w-[63px] items-center justify-between">
                    <img
                      onClick={() => {
                        navigate(`/detail/${id}`, {
                          state: { type: "PublicPG", itemNum: id },
                        });
                      }}
                      className="w-5 h-5 cursor-pointer"
                      alt=""
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/edit-user.svg"
                    />
                    /
                    <img
                      className="w-5 h-5 cursor-pointer"
                      alt=""
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/delete-trash.svg"
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </TableDefault>
      </PageContainerWrap>
    </div>
  );
};

export default PublicGroundListings;
