import React from 'react';

interface InputProps {
    className?: string;
    
    onChange?:(e:React.ChangeEvent<HTMLInputElement>) => void;
    children?: any;
    
    title?: string;
    placeholder?: string
    type?:string;

    isError?:boolean;
}

const InputDefault = ({className, onChange, type, children, title, placeholder, isError}:InputProps) => {
    return (
        <div className={`${className} text-left`}>
            <div>{title}</div>
            <input type={type} onChange={onChange} placeholder={placeholder}  className={`${isError ? "border border-red" : ""} text-sm w-full mt-2`}/>    
            <div className={`${isError ? "text-pure-red" : "text-light-green"} text-xs mt-2`}>
            {children}
            </div>
        </div>

    );
};

export default InputDefault; 