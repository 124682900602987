import React from "react";

interface ButtonProps {
  className?: string;
  title?: string;

  onClick?: () => void;
}

const ButtonDefault = ({ className, title, onClick }: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={`${className} py-2 px-4 text-pure-white rounded-lg shadow-button`}
    >
      {title}
    </button>
  );
};

export default ButtonDefault;
