import PageHeaderTitle from "components/page/PageHeaderTitle";
import React, { useEffect, useState } from "react";
import { requestToMustOutdoor } from "utils/requestToMustoutdoor";

const PgIcon = () => {
  const [tabNum, setTabNum] = useState(0);
  const [restrict, setRestrict] = useState([]);
  const [allowedIcon, setAllowedIcon] = useState<any>({});
  const [prohibitIcon, setProhibitIcon] = useState<any>({});

  const tabTitle = [
    { id: 0, title: "Allowed Details" },
    { id: 1, title: "Prohibited Details" },
    { id: 2, title: "Nearby Facility" },
  ];

  const fetchIcons = () => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v3/restrict/fetch`,
    }).then((res) => {
      const data = res.data.data;
      setRestrict(data);
      return data;
    });
  };

  useEffect(() => {
    fetchIcons();
  }, []);

  const deleteRestrict = (id: number) => {
    requestToMustOutdoor({
      method: "DELETE",
      url: `/api/v3/restrict/${id}`,
    }).then((res) => {
      alert("삭제 되었습니다");
      return res.data.data;
    });
  };

  return (
    <div>
      <PageHeaderTitle title="Icons Setting - Private Ground" />
      <div
        className="border-b h-11 flex phone:whitespace-nowrap 
                  phone:overflow-x-scroll phone:noScroll"
      >
        {tabTitle?.map((item, idx) => {
          return (
            <div
              key={idx}
              onClick={() => {
                setTabNum(item.id);
              }}
              className={`
                  ${
                    item.id === tabNum
                      ? "bg-light-green text-pure-white"
                      : " text-input-text"
                  } px-4 items-center cursor-pointer flex focus:bg-light-green rounded-t-lg`}
            >
              {item.title}
            </div>
          );
        })}
      </div>
      {tabNum === 0 ? (
        <div className="flex">
          <div className="p-4 w-[784px] flex flex-wrap gap-4">
            {restrict?.map((item: any, idx: number) => {
              const iconUrl = item.iconUrl;
              const enName = item.enName;
              const allowed = item.allowed;
              if (allowed) {
                return (
                  <div
                    onClick={() => {
                      setAllowedIcon(item);
                    }}
                    key={idx}
                    className={`
                  ${
                    allowedIcon?.enName === enName
                      ? "border border-light-green"
                      : ""
                  }
                   cursor-pointer w-28 h-28 border border-input-border-color flex flex-col justify-around items-center text-center`}
                  >
                    <img className="w-16 h-16" src={iconUrl} alt="" />
                    <div className="text-xs">{enName}</div>
                  </div>
                );
              }
            })}
          </div>
          <div className="p-4 flex-1">
            <div className="flex justify-between items-center">
              <div className="flex">
                <div className="bg-[#f8f8f8] w-16 h-16 border border-input-border-color p-4">
                  <img className="w-8 h-8" src={allowedIcon?.iconUrl} alt="" />
                </div>
                <div className="flex flex-col ml-4 justify-between py-2 text-xs">
                  <div className="font-bold">File Name</div>
                  <div>{allowedIcon?.iconFileName}</div>
                </div>
              </div>
              <div
                onClick={() => {
                  if (window.confirm("정말 삭제할까요 ?")) {
                    deleteRestrict(allowedIcon?.id);
                  } else {
                    return;
                  }
                }}
                className="cursor-pointer text-xs text-pure-red"
              >
                Delete
              </div>
            </div>
            <hr className="my-4" />
            <div className="flex justify-between items-center">
              <div className="text-xs font-bold">Title</div>
              <div className="p-2 rounded-lg w-48 border border-input-border-color">
                {allowedIcon?.enName}
              </div>
            </div>
          </div>
        </div>
      ) : tabNum === 1 ? (
        <div className="flex">
          <div className="p-4 w-[784px] flex flex-wrap gap-4">
            {restrict?.map((item: any, idx: number) => {
              const iconUrl = item.iconUrl;
              const enName = item.enName;
              const allowed = item.allowed;
              if (!allowed) {
                return (
                  <div
                    onClick={() => {
                      setProhibitIcon(item);
                    }}
                    key={idx}
                    className={`
                  ${
                    prohibitIcon?.enName === enName
                      ? "border border-light-green"
                      : ""
                  }
                   cursor-pointer w-28 h-28 border border-input-border-color flex flex-col justify-around items-center text-center`}
                  >
                    <img className="w-16 h-16" src={iconUrl} alt="" />
                    <div className="text-xs">{enName}</div>
                  </div>
                );
              }
            })}
          </div>
          <div className="p-4 flex-1">
            <div className="flex justify-between items-center">
              <div className="flex">
                <div className="bg-[#f8f8f8] w-16 h-16 border border-input-border-color p-4">
                  <img className="w-8 h-8" src={prohibitIcon?.iconUrl} alt="" />
                </div>
                <div className="flex flex-col ml-4 justify-between py-2 text-xs">
                  <div className="font-bold">File Name</div>
                  <div>{prohibitIcon?.iconFileName}</div>
                </div>
              </div>
              <div
                onClick={() => {
                  if (window.confirm("정말 삭제할까요 ?")) {
                    deleteRestrict(prohibitIcon?.id);
                  } else {
                    return;
                  }
                }}
                className="cursor-pointer text-xs text-pure-red"
              >
                Delete
              </div>
            </div>
            <hr className="my-4" />
            <div className="flex justify-between items-center">
              <div className="text-xs font-bold">Title</div>
              <div className="p-2 rounded-lg w-48 border border-input-border-color">
                {prohibitIcon?.enName}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PgIcon;
