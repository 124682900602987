import React from "react";

interface pageHeaderTitleProps {
  title?: string;
  children?: any;
}

const PageHeaderTitle = ({ title, children }: pageHeaderTitleProps) => {
  return (
    <div className="flex justify-between items-center">
      <div className="text-[32px] font-bold my-8">{title}</div>
      {children}
    </div>
  );
};

export default PageHeaderTitle;
