import ButtonDefault from "components/HTMLTagCompo/ButtonDefault";
import TableDefault from "components/HTMLTagCompo/TableDefault";
import PageHeaderTitle from "components/page/PageHeaderTitle";
import { TYPES_COLUMNS } from "components/page/tableData";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { requestToMustOutdoor } from "utils/requestToMustoutdoor";

const LocationThemeManage = () => {
  const navigate = useNavigate();
  const [tabNum, setTabNum] = useState(0);

  const [themes, setThemes] = useState<any>([]);

  const tabTitle = [{ id: 0, title: "Location Thmem" }];

  const fetchTheme = useCallback(() => {
    requestToMustOutdoor({
      method: "GET",
      url: "/api/v3/theme/fetch",
    }).then((res) => {
      const data = res.data.data;
      setThemes(data?.content);
      return data;
    });
  }, []);

  useEffect(() => {
    fetchTheme();
  }, []);

  return (
    <div>
      <PageHeaderTitle title="Location Theme Manage">
        <ButtonDefault
          onClick={() => {
            navigate("/icon/add", { state: { type: "THEME" } });
          }}
          className="bg-light-green"
          title="Create Themes"
        ></ButtonDefault>
      </PageHeaderTitle>
      <div
        className="border-b h-11 flex phone:whitespace-nowrap 
                  phone:overflow-x-scroll phone:noScroll"
      >
        {tabTitle?.map((item, idx) => {
          return (
            <div
              key={idx}
              onClick={() => {
                setTabNum(item.id);
              }}
              className={`
                  ${
                    item.id === tabNum
                      ? "bg-light-green text-pure-white"
                      : " text-input-text"
                  } px-4 items-center cursor-pointer flex focus:bg-light-green rounded-t-lg`}
            >
              {item.title}
            </div>
          );
        })}
      </div>
      {tabNum === 0 ? (
        <div className="p-4">
          <div className="flex justify-between mb-8">
            <div className="text-xl font-bold">List</div>
            <div>List</div>
          </div>
          <TableDefault COLUMNS={TYPES_COLUMNS}>
            {themes?.map((item: any, idx: number) => {
              const id = item.id;
              const title = item.title;
              const description = item.description;
              const updatedAt = item.updatedAt;
              const featured = item.featured;
              return (
                <tr key={idx}>
                  <td>{id}</td>
                  <td>{title}</td>
                  <td>{description}</td>
                  <td>{featured === true ? "YES" : "NO"}</td>
                  <td>{updatedAt !== null ? updatedAt : "-"}</td>
                  <td>
                    <div className="flex min-w-[63px] items-center justify-between">
                      <img
                        className="w-5 h-5 cursor-pointer"
                        alt=""
                        src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/edit-user.svg"
                      />
                      /
                      <img
                        className="w-5 h-5 cursor-pointer"
                        alt=""
                        src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/delete-trash.svg"
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </TableDefault>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default LocationThemeManage;
