import React, { useState } from "react";

import InputDefault from "components/HTMLTagCompo/InputDefault";
import ButtonDefault from "components/HTMLTagCompo/ButtonDefault";
import { requestToMustOutdoor } from "utils/requestToMustoutdoor";
import { useNavigate } from "react-router-dom";

const MainLogin = () => {
  const navigate = useNavigate();
  const [adminEmail, setAdminEmail] = useState("");
  const [adminPassword, setAdminPassword] = useState("");

  const login = () => {
    requestToMustOutdoor({
      method: "POST",
      url: "/auth/admin/log-in",
      data: { email: adminEmail, password: adminPassword },
    })
      .then((res) => {
        const data = res.data.data;
        const {
          accessToken,
          email,
          id,
          name,
          refreshToken,
          role,
          adminRole,
          adminId,
        } = data;
        localStorage.setItem('user', JSON.stringify({
          email: email,
          name: name,
          isLogin: accessToken,
          userId: id,
          adminId: adminId
        }))
        // localStorage.setItem("email", email);
        // localStorage.setItem("name", name);
        // localStorage.setItem("m", id);
        // localStorage.setItem("isLogin", accessToken);
        // localStorage.setItem("adminId", adminId);
        localStorage.setItem("role", role);
        localStorage.setItem("adminRole", adminRole);
        if (refreshToken !== null || refreshToken !== undefined) {
          localStorage.setItem("refresh", refreshToken);
        }
        navigate("/main", { replace: true });
        return res.data;
      })
      .catch((err) => {
        console.log(err?.response?.data?.message)
        alert(`에러 최용현에게 문의하세요\n에러내용: ${err?.response?.data?.message}`)
      });
  };
  return (
    <div className="p-8 block items-center text-center">
      <div className="border border-input-text rounded-lg py-8 px-20 w-[480px] mx-auto">
        <div className="font-bold text-xl">MUST OUTDOOR Admin Login</div>
        <InputDefault
          onChange={(e) => {
            setAdminEmail(e.target.value);
          }}
          type="text"
          placeholder="Enter Email"
          className="my-4"
          title="Email"
        ></InputDefault>
        <InputDefault
          onChange={(e: {
            target: { value: React.SetStateAction<string> };
          }) => {
            setAdminPassword(e.target.value);
          }}
          type="password"
          placeholder="Enter Password"
          className="my-4"
          title="Password"
        >
        </InputDefault>
        <ButtonDefault
          onClick={() => {
            login();
          }}
          title="Login"
          className="bg-light-green"
        />
        <div className="flex mt-8 justify-between">
          <div>Don't you have an account?</div>
          <div
            onClick={() => {
              navigate("sign-up");
            }}
            className="italic underline font-bold cursor-pointer"
          >
            Sign up
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLogin;
