export const getEveryDiffDay = (startDay:any, endDay:any) => {
    let regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
	if(!(regex.test(startDay) && regex.test(endDay))) return ;
	let result = [];
	let curDate = new Date(startDay);
	while(curDate <= new Date(endDay)) {
		result.push(curDate.toISOString().split("T")[0]);
		curDate.setDate(curDate.getDate() + 1);
	}
	return result;
}

export const getDiffDay = (startDay:any, endDay:any) => {
    const start = new Date(startDay);
    const end = new Date(endDay);

    const diffDate = end.getTime() - start.getTime();

    return (diffDate / (1000 * 60 * 60 * 24)) + 1;
}