import React, { useEffect, useState } from "react";

import FormRow from "components/InputForm/FormRow";
import FormTitle from "components/InputForm/FormTitle";
import ButtonDefault from "components/HTMLTagCompo/ButtonDefault";
import { requestToMustOutdoor } from "utils/requestToMustoutdoor";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [rePassword, setRePassword] = useState("")
  const [name, setName] = useState("")
  const [mobile, setMobile] = useState("")

  const [day, setDay] = useState("")
  const [month, setMonth] = useState("")
  const [year, setYear] = useState("")
  const [birth, setBirth] = useState("")
  
  // useEffect(() => {
  //   requestToMustOutdoor({
  //     method: "GET",
  //     url: `${process.env.REACT_APP_API_URL}guest/main/common/code`,
  //   })
  //     .then((res) => {
  //       const countryData = res.data.countryCodeMap;
  //       console.log(countryData)
  //       setOptionCode(countryData);
  //       return res.data;
  //     })
  //     .catch((err) => {});
  // }, []);

  const signUpData = {
    birth: birth,
    checkPassword: rePassword,
    email: email,
    mobile: mobile.replaceAll(/[^0-9]/g, ""),
    mobileCountryCode: "+82",
    name: name,
    password: password
  }
  
  const signUp = () => {
    requestToMustOutdoor({
      method:"POST",
      url:"/auth/admin/sign-up",
      data: signUpData
    })
    .then((res) => {
      const data = res.data.data;
      navigate('/', {replace: true})
      return data
    })
    .catch((err) => {
      alert('회원가입 오류')
      console.log(err)
    })
  }

  const yearOption = () => {
    const arr = [];

    const startYear = 1900;
    const endYear = new Date().getFullYear();

    for (let i = endYear; i >= startYear; i--) {
      arr.push(i);
    }

    return arr;
  };

  const monthOption = () => {
    const arr = [];

    for (let i = 1; i <= 12; i++) {
      arr.push(i);
    }
    return arr;
  };

  const dayOption = () => {
    const arr = [];

    for (let i = 1; i <= 31; i++) {
      arr.push(i);
    }
    return arr;
  };

  useEffect(() => {
    const birth =
      year +
      "-" +
      ("00" + month.toString()).slice(-2) +
      "-" +
      ("00" + day).slice(-2);
    setBirth(birth);
  }, [day, month, year]);

  return (
    <div className="p-8 block items-center text-left">
      <div className="border p-4 border-input-text rounded-lg w-[944px] mx-auto">
        <div className="font-bold text-xl">MUST OUTDOOR Admin Sign Up</div>
        <FormTitle rowForm formTitle="이메일 주소*">
        <FormRow onChange={(e) => {
          setEmail(e.target.value)
        }} input size="L" placeholder="exam@mustoutdoor.com" />
        </FormTitle>
        <FormTitle rowForm formTitle="비밀번호*">
        <FormRow onChange={(e) => {
          setPassword(e.target.value)
        }}   type="password" input size="L" placeholder="Password" />
        </FormTitle>
        <FormTitle rowForm formTitle="비밀번호 재입력*">
        <FormRow onChange={(e) => {
          setRePassword(e.target.value)
        }} type="password" input size="L" placeholder="Re-enter Password*" />
        </FormTitle>
        <FormTitle rowForm formTitle="이름*">
        <FormRow onChange={(e) => {
          setName(e.target.value)
        }} input size="L" placeholder="Name" />
        </FormTitle>
        <FormTitle rowForm formTitle="전화번호">
          <div className="border mr-8 rounded-lg p-2 text-light-green font-bold">
            + 82
          </div>
        {/* <FormRow options={optionCode} onChange={(e) => {
          setCode(e.target.value)
        }} select marginR size="S" placeholder="Select Country Code" /> */}
        <FormRow onChange={(e) => {
          setMobile(e.target.value)
        }} input size="M" placeholder="Your Contact Number" />
        </FormTitle>
        {/* <FormTitle rowForm formTitle="나는">
        <FormRow select size="L" placeholder="Select Title" />
        </FormTitle> */}
        <FormTitle rowForm formTitle="생일">
        <FormRow onChange={(e) => {
          setDay(e.target.value)
        }} options={dayOption()} select size="S" marginR placeholder="Day" />
        <FormRow onChange={(e) => {
          setMonth(e.target.value)
        }}  options={monthOption()} select size="S" marginR placeholder="Month" />
        <FormRow onChange={(e) => {
        setYear(e.target.value)
      }}  options={yearOption()} select size="S" placeholder="Year" />
        </FormTitle>
        <ButtonDefault onClick={() => {
          signUp()
        }} className="bg-light-green" title="Sign Up !" />
      </div>
    </div>
  );
};

export default SignUp;
