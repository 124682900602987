import React from "react";

interface tableProps {
  COLUMNS?: any[];
  children?: any;
}
const TableDefault = ({ COLUMNS, children }: tableProps) => {
  return (
    <div className="w-full">
      <table>
        <thead>
          <tr>
            {COLUMNS?.map((item: any, idx: number) => {
              return <th key={idx}>{item.Header}</th>;
            })}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

export default TableDefault;
