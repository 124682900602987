import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { requestToMustOutdoor } from "utils/requestToMustoutdoor";
import DaumAddressSearch from "./daumAddressSearch";
import ButtonDefault from "./HTMLTagCompo/ButtonDefault";
import CheckBoxDefault from "./HTMLTagCompo/CheckBoxDefault";
import FormRow from "./InputForm/FormRow";
import FormTitle from "./InputForm/FormTitle";
import KakaoMap from "./KakaoMap";
import PageContainerWrap from "./page/PageContainerWrap";
import PageHeaderTitle from "./page/PageHeaderTitle";

const AddItem = () => {
  const [types, setTypes] = useState([]);
  const [themes, setThemes] = useState([]);
  const [facility, setFacility] = useState([]);
  const [Activity, setActivity] = useState([]);
  const [restrict, setRestrict] = useState([]);

  const [locationInfo, setLocationInfo] = useState();
  const [addressInfo, setAddressInfo] = useState<any>({
    lng: 0,
    lat: 0,
  });
  const [zipCode, setZipCode] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");

  const [fullAddress, setFullAddress] = useState("");

  const [acVehicle, setAcVehicle] = useState("");
  const [activityIds, setActivityIds] = useState<any>([]);
  const [allowedIds, setAllowedIds] = useState<any>([]);
  const [prohibitedIds, setProhibitedIds] = useState<any>([]);
  const [des, setDes] = useState("");
  const [directions, setDirections] = useState("");
  const [extraAddress, setExtraAdd] = useState("");
  const [extraPolicy, setExtraPol] = useState("");
  const [facilityIds, seFacilityIds] = useState<any>([]);
  const [typeId, setTypeId] = useState<number>(0);
  const [locationIds, setLococationIds] = useState<any>([]);
  const [imageList, setImageList] = useState([]);
  const [indicator, setIndicator] = useState({
    latitude: 0,
    longitude: 0,
  });

  const [title, setTitle] = useState("");
  const [spaceSize, setSpaceSize] = useState("");

  const memberId = Number(localStorage.getItem("m"));

  const publcRegist = {
    accessibleVehicle: acVehicle,
    activityIds: activityIds,
    address: address,
    allowedIds: allowedIds,
    city: city,
    country: "KR",
    description: des,
    direction: directions,
    extraAddress: extraAddress,
    extraPolicy: extraPolicy,
    facilityIds: facilityIds,
    imageList: [
      {
        fileName: "pic/18098bd4-107c-4847-90fa-ce318513f7e9.png",
        profile: false,
        url: "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/pic/18098bd4-107c-4847-90fa-ce318513f7e9.png",
      },
    ],
    indicator:
      addressInfo?.lat !== 0 && addressInfo?.lng !== 0
        ? {
            latitude: Number(addressInfo?.lat),
            longitude: Number(addressInfo?.lng),
          }
        : "",
    nearbyFacility: [
      {
        distance: "1.8",
        facility: "EV_CHARGING_STATION",
        name: "GS25 편의점",
      },
    ],
    picked: false,
    prohibitedIds: prohibitedIds,
    state: state,
    themeIds: locationIds,
    title: title,
    totalMemberId: memberId,
    touristSpotRegReqDto: [
      {
        description: "석모도에서 가장 유명한 절",
        photo: "string",
        photoUrl: "string",
        title: "전등사",
      },
    ],
    typeId: typeId,
    zipCode: zipCode,
  };

  console.log("publcRegist", publcRegist);

  const accessibleVehicle = [
    "Only Backpacker",
    "Up to compact / light size",
    "Up to mid / large size",
    "Up to compact RV (3 pax)",
    "Up to mid / large RV (5 pax)",
    "Small/mid Caravan or Trailer(3 pax)",
    "Large Caravan or Trailer (5 pax)",
  ];

  const fetchIcon = useCallback(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v3/type/fetch`,
    }).then((res) => {
      const data = res.data.data;
      setTypes(data?.content);
      return data;
    });
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v3/theme/fetch`,
    }).then((res) => {
      const data = res.data.data;
      setThemes(data?.content);
      return data;
    });
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v3/facility/fetch`,
    }).then((res) => {
      const data = res.data.data;
      setFacility(data);
      return data;
    });
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v3/activity/fetch`,
    }).then((res) => {
      const data = res.data.data;
      setActivity(data);
      return data;
    });
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v3/restrict/fetch`,
    }).then((res) => {
      const data = res.data.data;
      setRestrict(data);
      return data;
    });
  }, []);

  useEffect(() => {
    fetchIcon();
  }, []);

  useEffect(() => {
    const data: any = locationInfo;
    let fullAddress = data?.address;
    let extraAddress = "";
    if (data) {
      const state = data?.sido;
      const city = data?.sigungu;
      const roadname = data?.roadname;
      const addressData = data?.roadAddressEnglish.split(",");
      const addressnum = addressData[0];
      const zipCode = data?.zonecode;
      setZipCode(zipCode);
      setState(state);
      setCity(city);
      setAddress(roadname + " " + addressnum);
    }

    if (data?.addressType === "R") {
      if (data?.bname !== "") {
        extraAddress += data.bname;
      }
      if (data?.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data?.buildingName}` : data?.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    setFullAddress(fullAddress); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
    const config = {
      headers: {
        Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_REST_API_KEY}`,
      },
    };
    const url =
      "https://dapi.kakao.com/v2/local/search/address.json?query=" +
      fullAddress;
    axios.get(url, config).then(function (result) {
      // API호출
      if (
        result?.data !== undefined ||
        result?.data !== null ||
        result.data.documents.length !== 0
      ) {
        if (result?.data?.documents[0]?.x && result?.data?.documents[0]?.y) {
          // Kakao Local API로 검색한 주소 정보 및 위도, 경도값 저장
          setAddressInfo({
            lng: result.data.documents[0].x,
            lat: result.data.documents[0].y,
          });
        }
      }
    });
  }, [locationInfo]);

  console.log(typeId);

  const handleType = (checked: string, item: number, e: any) => {
    if (checked && !typeId) {
      const result = item;
      setTypeId(result);
    } else if (!checked) {
      setTypeId(0);
    }
    if (typeId && checked) {
      alert("1개만 선택 가능합니다");
      e.target.checked = false;
    }
  };

  const LocationThemeSetting = (checked: string, item: string, e: any) => {
    if (checked) {
      const result = Number(item);
      setLococationIds([...locationIds, result]);
    } else if (!checked) {
      const result = Number(item);
      setLococationIds(locationIds.filter((el: any) => el !== result));
    }
    if (locationIds.length > 2 && checked) {
      const result = Number(item);
      alert("최대 3개까지 선택 가능합니다");
      setLococationIds(locationIds.filter((el: any) => el !== result));
      e.target.checked = false;
    }
  };

  const Facility = (checked: string, item: number) => {
    if (checked) {
      const result = Number(item);
      seFacilityIds([...facilityIds, result]);
    } else if (!checked) {
      const result = Number(item);
      seFacilityIds(facilityIds?.filter((el: any) => el !== result));
    }
  };

  const Activities = (checked: string, item: string) => {
    if (checked) {
      const result = Number(item);
      setActivityIds([...activityIds, result]);
    } else if (!checked) {
      const result = Number(item);
      setActivityIds(activityIds?.filter((el: any) => el !== result));
    }
  };

  const AllowedDetails = (checked: string, item: string) => {
    if (checked) {
      const result = Number(item);
      setAllowedIds([...allowedIds, result]);
    } else if (!checked) {
      const result = Number(item);
      setAllowedIds(allowedIds?.filter((el: any) => el !== result));
    }
  };

  const ProhibitedDetails = (checked: string, item: string) => {
    if (checked) {
      const result = Number(item);
      setProhibitedIds([...prohibitedIds, result]);
    } else if (!checked) {
      const result = Number(item);
      setProhibitedIds(prohibitedIds?.filter((el: any) => el !== result));
    }
  };

  return (
    <div>
      <PageHeaderTitle title="Add Public Ground Listing" />
      <PageContainerWrap
        inputWrap
        className="w-full"
        title="Listing information details"
      >
        <FormTitle formTitle="Title*">
          <FormRow
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            placeholder="Name of Private Ground"
            input
            size="L"
          />
        </FormTitle>
        <FormTitle formTitle="Description*">
          <FormRow
            onChange={(e) => {
              setDes(e.target.value);
            }}
            placeholder="Describe the Private Ground for guests"
            row={5}
            textArea
            size="L"
          />
        </FormTitle>
        <FormTitle formTitle="Ground Type*">
          <div className={`flex web:flex-row flex-wrap text-sm`}>
            {types?.map((item: any, idx) => {
              console.log(item);
              return (
                <CheckBoxDefault
                  onChange={(e: any) => {
                    console.log(item.id);
                    handleType(e.target.checked, item.id, e);
                  }}
                  key={idx}
                  imageUrl={item.iconUrl}
                  title={item.enName}
                />
              );
            })}
          </div>
        </FormTitle>
        <FormTitle formTitle="Availability*">
          <FormRow
            options={accessibleVehicle}
            marginR
            onChange={(e) => {
              setAcVehicle(e.target.value);
            }}
            placeholder="Accessible Vehicle"
            select
            size="S"
          />
          <FormRow input placeholder="Space Size" inputOnText="㎡" size="S" />
        </FormTitle>
        <FormTitle formTitle="Location Theme">
          <div className={`flex web:flex-row flex-wrap text-sm`}>
            {themes?.map((item: any, idx) => {
              return (
                <CheckBoxDefault
                  onChange={(e) => {
                    LocationThemeSetting(
                      e.target.checked,
                      e.currentTarget.id,
                      e
                    );
                  }}
                  key={idx}
                  imageUrl={item.iconUrl}
                  title={item.enName}
                />
              );
            })}
          </div>
        </FormTitle>
        <FormTitle col formTitle="Ground Address*">
          <DaumAddressSearch setGoogleMapInfo={setLocationInfo} />
          <FormRow
            options={["KR"]}
            marginT
            placeholder="Country / Region"
            select
            size="L"
          />
          <FormRow
            value={state}
            readOnly
            marginT
            placeholder="State"
            input
            size="L"
          />
          <FormRow
            value={city}
            readOnly
            marginT
            placeholder="City"
            input
            size="L"
          />
          <FormRow
            value={address}
            readOnly
            marginT
            placeholder="Street Address"
            input
            size="L"
          />
          <FormRow
            marginT
            onChange={(e) => setExtraAdd(e.target.value)}
            placeholder="Rest Address (optional)"
            input
            size="L"
          />
          <FormRow
            value={zipCode}
            readOnly
            marginT
            placeholder="Zip Code"
            input
            size="L"
          />
        </FormTitle>
        <FormTitle formTitle="Map View">
          <KakaoMap AddInfo={addressInfo} />
        </FormTitle>
        <FormTitle formTitle="How To Get There">
          <FormRow
            onChange={(e) => {
              setDirections(e.target.value);
            }}
            textArea
            row={5}
            size="L"
          />
        </FormTitle>
        <FormTitle col formTitle="Nearby Facility">
          <div className="flex">
            <FormRow placeholder="Convenience Store" input marginR size="M" />
            <FormRow inputOnText="Km" input size="S" />
          </div>
          <div className="flex mt-4">
            <FormRow placeholder="Supermarket" input marginR size="M" />
            <FormRow inputOnText="Km" input size="S" />
          </div>
          <div className="flex mt-4">
            <FormRow placeholder="24hr Toilet" input marginR size="M" />
            <FormRow inputOnText="Km" input size="S" />
          </div>
          <div className="flex mt-4">
            <FormRow placeholder="Gas Station" input marginR size="M" />
            <FormRow inputOnText="Km" input size="S" />
          </div>
          <div className="flex mt-4">
            <FormRow placeholder="EV Charging Station" input marginR size="M" />
            <FormRow inputOnText="Km" input size="S" />
          </div>
        </FormTitle>
        <FormTitle formTitle="Recommending Tourist Spot">
          <div className="flex">
            <div className="w-48 h-[141px] rounded-lg border border-input-border-color flex flex-col justify-center">
              <img
                className="mx-auto w-16 h-16"
                src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/gallery.svg"
                alt=""
              />
            </div>
            <div className="flex-col ml-8 flex justify-between">
              <FormRow placeholder="title" input size="M" />
              <FormRow placeholder="description" textArea size="M" />
            </div>
          </div>
        </FormTitle>
        <FormTitle formTitle="Facility*">
          <div className={`flex web:flex-row flex-wrap text-sm`}>
            {facility?.map((item: any, idx) => {
              return (
                <CheckBoxDefault
                  onChange={(e: any) => Facility(e.target.checked, e.target.id)}
                  key={idx}
                  imageUrl={item.iconUrl}
                  title={item.enName}
                />
              );
            })}
          </div>
        </FormTitle>
        <FormTitle formTitle="Activities*">
          <div className={`flex web:flex-row flex-wrap text-sm`}>
            {Activity?.map((item: any, idx) => {
              return (
                <CheckBoxDefault
                  onChange={(e: any) =>
                    Activities(e.target.checked, e.target.id)
                  }
                  key={idx}
                  imageUrl={item.iconUrl}
                  title={item.enName}
                />
              );
            })}
          </div>
        </FormTitle>
        <FormTitle formTitle="Allowed Details*">
          <div className={`flex web:flex-row flex-wrap text-sm`}>
            {restrict?.map((item: any, idx) => {
              const allowed = item?.allowed;
              if (allowed) {
                return (
                  <CheckBoxDefault
                    onChange={(e: any) =>
                      AllowedDetails(e.target.checked, e.target.id)
                    }
                    key={idx}
                    imageUrl={item.iconUrl}
                    title={item.enName}
                  />
                );
              }
            })}
          </div>
        </FormTitle>
        <FormTitle formTitle="Prohibited Details*">
          <div className={`flex web:flex-row flex-wrap text-sm`}>
            {restrict?.map((item: any, idx) => {
              const allowed = item?.allowed;
              if (!allowed) {
                return (
                  <CheckBoxDefault
                    key={idx}
                    onChange={(e: any) =>
                      ProhibitedDetails(e.target.checked, e.target.id)
                    }
                    imageUrl={item.iconUrl}
                    title={item.enName}
                  />
                );
              }
            })}
          </div>
        </FormTitle>
        <FormTitle formTitle="Ground Gallery*">
          <div className="w-full flex rounded-lg shadow-sm flex-col items-center justify-center border border-input-border-color min-h-[382px]">
            <img
              className="w-32 h-32"
              src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/gallery.svg"
              alt=""
            />
          </div>
        </FormTitle>
        <FormTitle formTitle="Additional Rule / Policy">
          <FormRow textArea row={5} size="L" />
        </FormTitle>
        <ButtonDefault title="Save Listing Information" className="bg-tan" />
      </PageContainerWrap>
    </div>
  );
};

export default AddItem;
