import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ChangeDate } from "utils/changeDate";
import { getEveryDiffDay } from "utils/getDiffDay";
import { requestToMustOutdoor } from "utils/requestToMustoutdoor";
import FormRow from "./InputForm/FormRow";
import FormTitle from "./InputForm/FormTitle";
import PageContainerWrap from "./page/PageContainerWrap";
import PageHeaderTitle from "./page/PageHeaderTitle";

const BookingDetail = () => {
  const { state } = useLocation();

  console.log(state);

  const [bookingInfo, setBookingInfo] = useState<any>([]);

  const { pgId, reservationId, createAt, updateAt } = state;

  const bookingDetail = useCallback(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v3/reservation/${pgId}/${reservationId}`,
    }).then((res) => {
      const data = res.data.data;
      setBookingInfo(data);
      return data;
    });
  }, []);

  useEffect(() => {
    bookingDetail();
  }, []);

  const {
    status,
    pgCurrentImage,
    pgTitle,
    typeIcon,
    typeEnName,
    pgUnitAmount,
    pgAccessibleVehicle,
    pgSpaceSize,
    reservationCode,
    checkIn,
    checkOut,
    travelerImage,
    hostImage,
    hostName,
    travelerName,
    hostLanguages,
    travelerLanguages,
    plateNumber,
    travelerMobileCode,
    travelerMobile,
    hostResponseRate,
    hostResponseTime,
    hostMobileCode,
    hostMobile,
    checkInTime,
    checkOutTime,
    pgAdvanced,
    pgExtraPolicy,
    policyName,
    bookingOptionInfo,
    amount,
    price,
    totalPrice,
    reservationCreatedAt,
    merchantUid,
    paymentMethod,
  } = bookingInfo;
  console.log(bookingInfo);

  const getAllDay: any = getEveryDiffDay(checkIn, checkOut);

  const dayLength = getAllDay?.length - 1;

  return (
    <div>
      <PageHeaderTitle title="Booking Information" />
      <PageContainerWrap title="Booking Information Details">
        <FormTitle rowForm formTitle="Requested At">
          <FormRow readOnly value={createAt} size="L" input />
        </FormTitle>
        <FormTitle rowForm formTitle="Updated At">
          <FormRow
            readOnly
            value={updateAt === "Invalid Date" ? "-" : updateAt}
            size="L"
            input
          />
        </FormTitle>
      </PageContainerWrap>
      <div className="w-full bg-[#ebebeb] p-8">
        {status === "COMPLETED" ? (
          <div className="w-full bg-light-green rounded-lg text-pure-white p-4 flex">
            <img
              className="w-16 h-16 mr-4"
              src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/status-confirmed-approve.svg"
              alt=""
            />
            <div className="flex flex-col justify-between font-bold">
              <div className="text-2xl">Booking is confirmed</div>
              <div className="text-sm">
                Redeem your outdoor life with Booking#. It’s time to explore
                your Outdoor Life!
              </div>
            </div>
          </div>
        ) : status === "PENDING" ? (
          <div className="w-full bg-pending rounded-lg text-pure-white p-4 flex">
            <img
              className="w-16 h-16 mr-4"
              src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/pending.svg"
              alt=""
            />
            <div className="flex flex-col justify-between">
              <div className="text-2xl font-bold">Booking is Pending</div>
              <div className="text-sm">
                Your booking is requested to the host and waiting for approval.
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="flex mt-8">
          <div>
            <div className="w-[672px] mb-8">
              <div className="bg-light-green h-12 rounded-t-lg px-4 py-3 text-pure-white font-bold">
                Booking Detail
              </div>
              <div className="bg-pure-white p-4">
                <div className="flex">
                  <img
                    className="w-[276px] h-40 rounded-lg mr-2"
                    src={pgCurrentImage}
                    alt=""
                  />
                  <div className="flex-1 flex flex-col justify-between">
                    <div className="flex w-full items-center justify-between">
                      <div className="text-xl font-bold w-72 text-ellipsis whitespace-nowrap overflow-hidden">
                        {pgTitle}
                      </div>
                      {/* <div>View</div> */}
                    </div>
                    <div>
                      <div className="flex items-center">
                        <img className="w-6 h-6 mr-2" src={typeIcon} alt="" />
                        <div>{typeEnName}</div>
                      </div>
                      <div className="flex my-4 items-center">
                        <div className="flex mr-4">
                          <img
                            className="w-6 h-6 mr-2"
                            src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/available.svg"
                            alt=""
                          />
                          <div>X {amount}</div>
                        </div>
                        <div className="flex">
                          <img
                            className="w-6 h-6 mr-2"
                            src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/accessible-vehicle.svg"
                            alt=""
                          />
                          <div>{pgAccessibleVehicle}</div>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <img
                          className="w-6 h-6 mr-2"
                          src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/space-size.svg"
                          alt=""
                        />
                        <div>{pgSpaceSize}㎡</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-8 font-bold">
                  <div className="flex items-center">
                    <div className="w-56">Booking #</div>
                    <div>{reservationCode}</div>
                  </div>
                </div>
                <div className="flex items-center mt-8">
                  <div className="w-56 font-bold">Schedule</div>
                  <div className="flex items-center">
                    <div className="w-56">
                      <div className="text-sm">Check-In</div>
                      <div className="font-bold">{checkIn}</div>
                    </div>
                    <div>
                      <div className="text-sm">Check-Out</div>
                      <div className="font-bold">{checkOut}</div>
                    </div>
                  </div>
                </div>
                <div className="flex font-bold items-center mt-8">
                  <div className="w-56">Ground Unit</div>
                  <div className="flex items-center">
                    <div className="w-[208px] mr-4">{pgTitle}</div>
                    <div>X {amount}</div>
                  </div>
                </div>
                {bookingOptionInfo?.length !== 0 ? (
                  <div className="flex font-bold items-center mt-8">
                    <div className="w-56">Extra Option(s)</div>
                    {bookingOptionInfo?.map((item: any, idx: number) => {
                      const value = item.pgExtraOptionId;
                      return (
                        <div key={idx} className="flex items-center">
                          <div className="w-[208px] mr-4">{value?.title}</div>
                          <div>X {item.amount}</div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="w-[672px]">
              <div className="bg-light-green h-12 rounded-t-lg px-4 py-3 text-pure-white font-bold">
                Policy
              </div>
              <div className="bg-pure-white p-4">
                <div className="w-full mb-8 border-input-text border bg-[#eeeeee] rounded-lg p-2">
                  <div className="flex font-bold items-start">
                    <img
                      alt=""
                      src={
                        "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/warning-yellow.svg"
                      }
                      className="mr-2 w-5 h-5"
                    />
                    <div>Notice</div>
                  </div>
                  <div className="text-sm">
                    Must Outdoor will enforce following cancellation policy to
                    protect both guest and host alike. Each listing and
                    reservation on our site will clearly state the cancellation
                    policy. Guests may cancel and review any penalties by
                    viewing travel plans and then clicking 'Cancel' on the
                    appropriate reservation.
                  </div>
                </div>
                <div className="mb-8">
                  <div className="font-bold">Check In / Out</div>
                  <div className="flex mt-4">
                    <div className="w-56">Check In : {checkInTime}</div>
                    <div>Check Out : {checkOutTime}</div>
                  </div>
                </div>
                <div className="mb-8">
                  <div className="font-bold">Confirmation</div>
                  <div className="mt-4">
                    <div className="mb-2">
                      • You will receive confirmation of your booking's
                      availability within 24 Hours. Once confirmed, we will send
                      you the voucher via email
                    </div>
                    <div>
                      • In the event that you do not receive an email from us,
                      please check your Spam folder or notify us via email
                    </div>
                  </div>
                </div>
                <div className="mb-8">
                  <div className="font-bold">Advance Notice</div>
                  <div className="mt-4">
                    <div className="mb-2">
                      {pgAdvanced
                        ? "Booking is not available today"
                        : "Booking is available today"}
                    </div>
                  </div>
                </div>
                <div className="mb-8">
                  <div className="font-bold">Additional Rule / Policy</div>
                  <div className="mt-4">
                    <div className="mb-2">{pgExtraPolicy}</div>
                  </div>
                </div>
                <div className="mb-8">
                  <div className="font-bold">Cancellation & Refund Policy</div>
                  <div className="mt-4">
                    <div className="mb-2 font-bold">
                      <span className="text-pure-red">{policyName}</span> Policy
                      on Cancellation
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 ml-8">
            <div className="mb-8">
              <div className="bg-light-green h-12 rounded-t-lg px-4 py-3 text-pure-white font-bold">
                Traveler
              </div>
              <div className="bg-pure-white p-4">
                <div className="flex items-center">
                  <img
                    src={
                      travelerImage
                        ? travelerImage
                        : "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/my-account-avatar.svg"
                    }
                    className="w-12 h-12 "
                    alt=""
                  />
                  <div className="flex flex-col justify-between ml-4">
                    <div className="text-[#979797]">Nickname</div>
                    <div className="font-bold">{travelerName}</div>
                  </div>
                </div>
                <div className="flex mt-4 justify-between items-center">
                  <div className="text-[#979797] text-sm">
                    Preferred Language
                  </div>
                  <div className="text-sm font-bold">{travelerLanguages}</div>
                </div>
                <div className="flex my-4 justify-between items-center">
                  <div className="text-[#979797] text-sm">Plate Number</div>
                  <div className="text-sm font-bold">{plateNumber}</div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="text-[#979797] text-sm">Contact Number</div>
                  <div className="text-sm font-bold">
                    {travelerMobileCode + " " + travelerMobile}
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-8">
              <div className="bg-light-green h-12 rounded-t-lg px-4 py-3 text-pure-white font-bold">
                Payment Details
              </div>
              <div className="bg-pure-white items-center p-4">
                <div className="flex">
                  <img
                    src={
                      hostImage
                        ? hostImage
                        : "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/my-account-avatar.svg"
                    }
                    className="w-12 h-12 "
                    alt=""
                  />
                  <div className="flex flex-col justify-between ml-4">
                    <div className="text-[#979797]">Hosted by</div>
                    <div className="font-bold">{hostName}</div>
                  </div>
                </div>
                <div className="flex my-4 justify-between items-center">
                  <div className="text-[#979797] text-sm">
                    Preferred Language
                  </div>
                  <div className="text-sm font-bold">{hostLanguages}</div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="text-[#979797] text-sm">Response Rate</div>
                  <div className="text-sm font-bold">{hostResponseRate}</div>
                </div>
                <div className="flex my-4 justify-between items-center">
                  <div className="text-[#979797] text-sm">Response Time</div>
                  <div className="text-sm font-bold">{hostResponseTime}</div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="text-[#979797] text-sm">Contact Number</div>
                  <div className="text-sm font-bold">
                    {status === "PENDING"
                      ? "Show as confirmed"
                      : hostMobileCode + " " + hostMobile}
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-8">
              <div className="bg-light-green h-12 rounded-t-lg px-4 py-3 text-pure-white font-bold">
                Payment Details
              </div>
              <div className="bg-pure-white items-center p-4">
                <div>
                  <div className="font-bold">Ground Charge</div>
                  <div className="flex justify-between items-center text-sm mt-4">
                    <div className="max-w-[200px]">
                      <div>{pgTitle}</div>
                      <div className="text-xs text-[#979797]">
                        {amount} ground unit x {dayLength} night
                      </div>
                    </div>
                    <div>{price}</div>
                  </div>
                </div>

                {bookingOptionInfo?.length !== 0 ? (
                  <div>
                    <hr className="text-border-light my-4" />
                    <div className="font-bold">Extra Option(s)</div>
                    {bookingOptionInfo?.map((item: any, idx: number) => {
                      const value = item.pgExtraOptionId;
                      return (
                        <div
                          key={idx}
                          className="flex justify-between items-center text-sm mt-4"
                        >
                          <div className="max-w-[200px]">
                            <div>{value?.title}</div>
                            <div className="text-xs text-[#979797]">
                              $ {value?.price} x {item?.amount}
                            </div>
                          </div>
                          <div>
                            {String(Number(value?.price * item?.amount))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
                <hr className="text-border-light my-4" />
                <div className="flex justify-between font-bold">
                  <div>Total Amount</div>
                  <div>{totalPrice}</div>
                </div>
              </div>
            </div>

            {status === "COMPLETED" ? (
              <div className="mb-8">
                <div className="bg-light-green h-12 rounded-t-lg px-4 py-3 text-pure-white font-bold">
                  Payment Receipt
                </div>
                <div className="bg-pure-white items-center p-4 text-sm">
                  <div className="flex justify-between">
                    <div className="text-input-text">Date</div>
                    <div>{ChangeDate(reservationCreatedAt)}</div>
                  </div>
                  <div className="flex justify-between my-4">
                    <div className="text-input-text">Transaction ID</div>
                    <div>{merchantUid ? merchantUid : "-"}</div>
                  </div>
                  <div className="flex justify-between">
                    <div className="text-input-text">Payment Method</div>
                    {paymentMethod ? paymentMethod : "-"}
                  </div>
                  <hr className="text-border-light my-4" />
                  <div className="flex justify-between">
                    <div className="text-input-text">Amount</div>
                    <div>{totalPrice}</div>
                  </div>
                  <hr className="text-border-light my-4" />
                  <div className="flex justify-between">
                    <div className="text-input-text">Total Amount</div>
                    <div className="text-xl font-bold text-main-green">
                      {totalPrice}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetail;
