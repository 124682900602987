import React from 'react';

const AutomaticNotification = () => {
    return (
        <div>
            AutomaticNotification
        </div>
    );
};

export default AutomaticNotification;