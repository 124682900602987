import TableDefault from "components/HTMLTagCompo/TableDefault";
import PageContainerWrap from "components/page/PageContainerWrap";
import PageHeaderTitle from "components/page/PageHeaderTitle";
import { REVIEW_COLUMNS } from "components/page/tableData";
import React from "react";

const Reviews = () => {
  return (
    <div>
      <PageHeaderTitle title="Reviews" />
      <PageContainerWrap title="Reviews Lists">
        <TableDefault COLUMNS={REVIEW_COLUMNS}></TableDefault>
      </PageContainerWrap>
    </div>
  );
};

export default Reviews;
