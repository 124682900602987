import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const SideTab = () => {
  const navigate = useNavigate();

  const [toggle, setToggle] = useState<number[]>([]);
  return (
    <div className="box-border w-64 border h-[856px] overflow-y-auto">
      <div
        onClick={() => {
          navigate("/main");
        }}
        className="sideTabTitle"
      >
        Dashboard
      </div>
      <div
        onClick={() => {
          navigate("/manage/admin");
        }}
        className="sideTabTitle"
      >
        Magage Admin
      </div>
      <div
        onClick={() => {
          navigate("/manage/user");
        }}
        className="sideTabTitle"
      >
        Manage Users
      </div>
      <div
        onClick={() => {
          navigate("/manage/host");
        }}
        className="sideTabTitle"
      >
        Manage Host
      </div>
      <div className="sideTabTitle noClick">
        <div
          onClick={() => {
            !toggle.includes(0)
              ? setToggle((toggle: any) => [...toggle, 0])
              : setToggle(toggle.filter((x) => x !== 0));
          }}
          className="flex justify-between items-center"
        >
          <div>Listings</div>
          <img
            className={`${
              toggle.includes(0) ? "rotate-180" : ""
            } w-3 h-3 transition-transform`}
            src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-up.svg"
            alt=""
          />
        </div>
        {toggle?.includes(0) ? (
          <div>
            <div
              onClick={() => {
                navigate("/accommodation");
              }}
              className="toggle"
            >
              Accommodation
            </div>
            <div
              onClick={() => {
                navigate("/private");
              }}
              className="toggle"
            >
              Private Ground
            </div>
            <div
              onClick={() => {
                navigate("/activity");
              }}
              className="toggle"
            >
              Activities
            </div>
            <div
              onClick={() => {
                navigate("/public");
              }}
              className="toggle"
            >
              Admin Public Ground
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        onClick={() => {
          navigate("/policy");
        }}
        className="sideTabTitle"
      >
        Cancellation Policy
      </div>
      <div
        onClick={() => {
          navigate("/payment");
        }}
        className="sideTabTitle"
      >
        Payment Log
      </div>
      <div
        onClick={() => {
          navigate("/booking");
        }}
        className="sideTabTitle"
      >
        Booking Log
      </div>
      <div
        onClick={() => {
          navigate("/payout");
        }}
        className="sideTabTitle"
      >
        Payout
      </div>
      <div
        onClick={() => {
          navigate("/destination");
        }}
        className="sideTabTitle"
      >
        Destination Search
      </div>
      <div className="sideTabTitle noClick">
        <div
          onClick={() => {
            !toggle.includes(1)
              ? setToggle((toggle: any) => [...toggle, 1])
              : setToggle(toggle.filter((x) => x !== 1));
          }}
          className="flex justify-between items-center"
        >
          <div>Main Page Setting</div>
          <img
            className={`${
              toggle.includes(1) ? "rotate-180" : ""
            } w-3 h-3 transition-transform`}
            src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-up.svg"
            alt=""
          />
        </div>
        {toggle?.includes(1) ? (
          <div>
            <div
              onClick={() => {
                navigate("/slider");
              }}
              className="toggle"
            >
              Main Slider
            </div>
            <div
              onClick={() => {
                navigate("/theme");
              }}
              className="toggle"
            >
              Theme Setting
            </div>
            <div
              onClick={() => {
                navigate("/popular");
              }}
              className="toggle"
            >
              Popular Destination
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="sideTabTitle noClick">
        <div
          onClick={() => {
            !toggle.includes(2)
              ? setToggle((toggle: any) => [...toggle, 2])
              : setToggle(toggle.filter((x) => x !== 2));
          }}
          className="flex justify-between items-center"
        >
          <div>Message</div>
          <img
            className={`${
              toggle.includes(2) ? "rotate-180" : ""
            } w-3 h-3 transition-transform`}
            src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-up.svg"
            alt=""
          />
        </div>
        {toggle?.includes(2) ? (
          <div>
            <div
              onClick={() => {
                navigate("/noti/manual");
              }}
              className="toggle"
            >
              Manual Notification
            </div>
            <div
              onClick={() => {
                navigate("/noti/auto");
              }}
              className="toggle"
            >
              Automatic Notification
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="sideTabTitle noClick">
        <div
          onClick={() => {
            !toggle.includes(3)
              ? setToggle((toggle: any) => [...toggle, 3])
              : setToggle(toggle.filter((x) => x !== 3));
          }}
          className="flex justify-between items-center"
        >
          <div>Email Settings</div>
          <img
            className={`${
              toggle.includes(3) ? "rotate-180" : ""
            } w-3 h-3 transition-transform`}
            src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-up.svg"
            alt=""
          />
        </div>
        {toggle?.includes(3) ? (
          <div>
            <div
              onClick={() => {
                navigate("/email/template");
              }}
              className="toggle"
            >
              Email Template
            </div>
            <div
              onClick={() => {
                navigate("/email/setting");
              }}
              className="toggle"
            >
              Email Send Setting
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        onClick={() => {
          navigate("/review");
        }}
        className="sideTabTitle"
      >
        Reviews
      </div>
      <div
        onClick={() => {
          navigate("/exchange");
        }}
        className="sideTabTitle"
      >
        Exchange Rate
      </div>
      <div
        onClick={() => {
          navigate("/faq");
        }}
        className="sideTabTitle"
      >
        FAQ Setting
      </div>
      <div
        onClick={() => {
          navigate("/icon/type");
        }}
        className="sideTabTitle"
      >
        Types Manage
      </div>
      <div
        onClick={() => {
          navigate("/icon/theme");
        }}
        className="sideTabTitle"
      >
        Location Theme Manage
      </div>
      <div className="sideTabTitle noClick">
        <div
          onClick={() => {
            !toggle.includes(4)
              ? setToggle((toggle: any) => [...toggle, 4])
              : setToggle(toggle.filter((x) => x !== 4));
          }}
          className="flex justify-between items-center"
        >
          <div>Icons Setting</div>
          <img
            className={`${
              toggle.includes(4) ? "rotate-180" : ""
            } w-3 h-3 transition-transform`}
            src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/arrow-up.svg"
            alt=""
          />
        </div>
        {toggle?.includes(4) ? (
          <div>
            <div
              onClick={() => {
                navigate("/icon/common");
              }}
              className="toggle"
            >
              Common
            </div>
            <div
              onClick={() => {
                // navigate("/email/setting");
              }}
              className="toggle"
            >
              General
            </div>
            <div
              onClick={() => {
                navigate("/icon/pg");
              }}
              className="toggle"
            >
              Private Ground
            </div>
            <div
              onClick={() => {
                navigate("/icon/ac");
              }}
              className="toggle"
            >
              Accommodation
            </div>
            <div
              onClick={() => {
                navigate("/icon/at");
              }}
              className="toggle"
            >
              Activity
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SideTab;
