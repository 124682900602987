import TableDefault from "components/HTMLTagCompo/TableDefault";
import PageContainerWrap from "components/page/PageContainerWrap";
import PageHeaderTitle from "components/page/PageHeaderTitle";
import { EMAILSEND_COLUMNS } from "components/page/tableData";
import React from "react";

const EmailSendSetting = () => {
  return (
    <div>
      <PageHeaderTitle title="Email Send Setting" />
      <PageContainerWrap title="Email Send Setting Lists">
        <TableDefault COLUMNS={EMAILSEND_COLUMNS}></TableDefault>
      </PageContainerWrap>
    </div>
  );
};

export default EmailSendSetting;
