import FormRow from "components/InputForm/FormRow";
import FormTitle from "components/InputForm/FormTitle";
import PageContainerWrap from "components/page/PageContainerWrap";
import PageHeaderTitle from "components/page/PageHeaderTitle";
import React from "react";

const MainSlider = () => {
  return (
    <div>
      <PageHeaderTitle title="Main Slider Setting" />
      <PageContainerWrap title="Main Slider Setting">
        <FormTitle rowForm formTitle="Language*">
          <FormRow input size="L" />
        </FormTitle>
        <FormTitle rowForm formTitle="Slider Title*">
          <FormRow input size="L" />
        </FormTitle>
        <FormTitle rowForm formTitle="Slider Subtitle">
          <FormRow textArea size="L" />
        </FormTitle>
        <div className="flex">
          <div className="w-[270px]">
            <div>Slider Images*</div>
            <div className="mt-2 inline-block bg-light-green text-pure-white py-2 px-4 rounded-lg">
              Image Upload
            </div>
          </div>
          <div className="flex-1 border max-w-[640px]"></div>
        </div>
        <hr className="my-8 max-w-[910px]" />
      </PageContainerWrap>
    </div>
  );
};

export default MainSlider;
