import TableDefault from "components/HTMLTagCompo/TableDefault";
import PageContainerWrap from "components/page/PageContainerWrap";
import PageHeaderTitle from "components/page/PageHeaderTitle";
import { FAQ_SETTING } from "components/page/tableData";
import React from "react";

const FAQSetting = () => {
  return (
    <div>
      <PageHeaderTitle title="FAQ Setting" />
      <PageContainerWrap title="Faq">
        <TableDefault COLUMNS={FAQ_SETTING} />
      </PageContainerWrap>
    </div>
  );
};

export default FAQSetting;
