import TableDefault from "components/HTMLTagCompo/TableDefault";
import Pagenation from "components/Pagenation";
import PageContainerWrap from "components/page/PageContainerWrap";
import PageHeaderTitle from "components/page/PageHeaderTitle";
import { USER_MANAGE } from "components/page/tableData";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChangeDate } from "utils/changeDate";
import { requestToMustOutdoor } from "utils/requestToMustoutdoor";

const ManageUsers = () => {
  const navigate = useNavigate();
  const [memberData, setMemberData] = useState([]);

  const [total, setTotal] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v3/users/fetch`,
      params: { email: "", name: "", page: currentPage, size: 10, sort: "" },
    }).then((res) => {
      const data = res.data.data;
      const totalPages = data.totalPages;
      const size = data.size;
      setTotal(totalPages);
      setLimit(size);
      setMemberData(data?.content);
      return data;
    });
  }, [currentPage]);

  return (
    <div>
      <PageHeaderTitle title="Manage User" />
      <PageContainerWrap title="Users">
        <TableDefault COLUMNS={USER_MANAGE}>
          {memberData?.map((item: any, idx) => {
            const id = item.id;
            const name = item.name;
            const email = item.email;
            const verified = String(item.verified);
            const marketing = String(item.marketingConsent);
            const joined_at = ChangeDate(item.createdAt);
            const last_login = ChangeDate(item.lastLoginAt);

            const adminRole = item.adminRole;
            return (
              <tr key={idx}>
                <td>{id}</td>
                <td>{name}</td>
                <td>{email}</td>
                <td>{verified}</td>
                <td>{marketing}</td>
                <td>{joined_at}</td>
                <td>{last_login}</td>
                <td>
                  <div className="flex min-w-[63px] items-center justify-between">
                    <img
                      onClick={() => {
                        navigate("/manage/user-info", {
                          state: { type: "user", id: id },
                        });
                      }}
                      className="w-5 h-5 cursor-pointer"
                      alt=""
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/edit-user.svg"
                    />
                    /
                    <img
                      className="w-5 h-5 cursor-pointer"
                      alt=""
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/delete-trash.svg"
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </TableDefault>
        <Pagenation
          total={total}
          currenct={currentPage}
          setPage={setCurrentPage}
        />
      </PageContainerWrap>
    </div>
  );
};

export default ManageUsers;
