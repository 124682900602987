import ButtonDefault from "components/HTMLTagCompo/ButtonDefault";
import TableDefault from "components/HTMLTagCompo/TableDefault";
import PageContainerWrap from "components/page/PageContainerWrap";
import PageHeaderTitle from "components/page/PageHeaderTitle";
import { POPULARDESTINATION_COLUMNS } from "components/page/tableData";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { requestToMustOutdoor } from "utils/requestToMustoutdoor";

const PopularDestination = () => {
  const [destinationData, setDestinationData] = useState<any>([]);

  const navigate = useNavigate();

  const fectDetsination = useCallback(() => {
    requestToMustOutdoor({
      method: "GET",
      url: "/api/v3/destination/fetch",
      params: { name: "", page: "", size: 10, sort: "" },
    }).then((res) => {
      const content = res.data.data.content;
      setDestinationData(content);
    });
  }, []);

  const deleteDestination = (id: number) => {
    requestToMustOutdoor({
      method: "DELETE",
      url: `/api/v3/destination/${id}`,
    })
      .then((res) => {
        alert("제거완료");
        window.location.reload();
        return res.data.data;
      })
      .catch((err) => {
        alert("제거실패");
        return err;
      });
  };

  useEffect(() => {
    fectDetsination();
  }, [fectDetsination]);
  return (
    <div>
      <PageHeaderTitle title="Popular Destination">
        <ButtonDefault
          onClick={() => {
            navigate("add");
          }}
          className="bg-light-green"
          title="Add Destination"
        ></ButtonDefault>
      </PageHeaderTitle>
      <PageContainerWrap title="Popular Destination List">
        <TableDefault COLUMNS={POPULARDESTINATION_COLUMNS}>
          {destinationData?.map((item: any, idx: number) => {
            const id = item?.id;
            const state = item?.state;
            const country = item?.country;
            const city = item?.city;
            const updatedAt = item?.updatedAt;
            return (
              <tr key={idx}>
                <td>{id}</td>
                <td>{city}</td>
                <td>뭔값</td>
                <td>뭔값</td>
                <td>{updatedAt !== null ? updatedAt : "업데이트X"}</td>
                <td>
                  <div className="flex min-w-[63px] items-center justify-between">
                    <img
                      onClick={() => {}}
                      className="w-5 h-5 cursor-pointer"
                      alt=""
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/edit-user.svg"
                    />
                    /
                    <img
                      onClick={() => {
                        deleteDestination(id);
                      }}
                      className="w-5 h-5 cursor-pointer"
                      alt=""
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/delete-trash.svg"
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </TableDefault>
      </PageContainerWrap>
    </div>
  );
};

export default PopularDestination;
