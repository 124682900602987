export const POLICY_COLUMNS = [
    {
        Header: 'Business Type',
    },
    {
        Header: 'Title of Cancellation Policy',
    },
    {
        Header: 'Last Updated',
    },
]
export const TYPES_COLUMNS = [
    {
        Header: 'ID',
    },
    {
        Header: 'Title',
    },
    {
        Header: 'Description',
    },
    {
        Header: 'Feature Option',
    },
    {
        Header: 'Updated at',
    }
]
export const CURRENCY_COLUMNS = [
    {
        Header: 'Currency',
    },
    {
        Header: 'Currency Symbol',
    },
    {
        Header: 'Exchange Rate',
    },
    {
        Header: 'Last Updated',
    },
    {
        Header: 'Action',
    }
]
export const REVIEW_COLUMNS = [
    {
        Header: 'Traveler Name',
    },
    {
        Header: 'Host Name',
    },
    {
        Header: 'Booking #',
    },
    {
        Header: 'Date of Use(Check-in)',
    },
    {
        Header: 'Created At',
    },
    {
        Header: 'Action',
    }
]
export const EMAILSEND_COLUMNS = [
    {
        Header: 'Language',
    },
    {
        Header: 'Trigger',
    },
    {
        Header: 'Recipient',
    },
    {
        Header: 'Title',
    },
    {
        Header: 'Action',
    }
]
export const NOTIFICATION_COLUMNS = [
    {
        Header: 'Language',
    },
    {
        Header: 'Trigger',
    },
    {
        Header: 'Recipient',
    },
    {
        Header: 'Title',
    },
    {
        Header: 'Action',
    }
]
export const POPULARDESTINATION_COLUMNS = [
    {
        Header: 'ID',
    },
    {
        Header: 'Destination',
    },
    {
        Header: 'Import Destination from search list',
    },
    {
        Header: 'Items',
    },
    {
        Header: 'Updated',
    },
    {
        Header: 'Action',
    }
]
export const THEME_COLUMNS = [
    {
        Header: 'ID',
    },
    {
        Header: 'Title',
    },
    {
        Header: 'Description',
    },
    {
        Header: 'Category',
    },
    {
        Header: 'Translation',
    },
    {
        Header: 'Updated',
    },
    {
        Header: 'Action',
    }
]
export const PAYMENT_COLUMNS = [
    {
        Header: 'Traveler',
    },
    {
        Header: 'Booking #',
    },
    {
        Header: 'PG',
    },
    {
        Header: 'Payment Amount',
    },
    {
        Header: 'Status',
    },
    {
        Header: 'Last Payout',
    },
    {
        Header: 'Updated',
    },
    {
        Header: 'Action',
    }
]
export const DESTINATION_COLUMNS = [
    {
        Header: 'Search',
    },
    {
        Header: 'ID',
    },
    {
        Header: 'Destination',
    },
    {
        Header: 'Import Destination',
    },
    {
        Header: 'Sub destination',
    },
    {
        Header: 'Items',
    },
    {
        Header: 'Updated at',
    },
    {
        Header: 'Action',
    }
]
export const PAYOUT_COLUMNS = [
    {
        Header: 'ID',
    },
    {
        Header: 'Host Name',
    },
    {
        Header: 'Business Type',
    },
    {
        Header: 'Title',
    },
    {
        Header: 'Last Payout',
    },
    {
        Header: 'Created at',
    },
    {
        Header: 'Action',
    }
]

export const PRIVATE_COLUMNS = [
    {
        Header: 'ID',
    },
    {
        Header: 'Title',
    },
    {
        Header: 'Destination',
    },
    {
        Header: 'Status',
    },
    {
        Header: 'Featured',
    },
    {
        Header: 'Viewed',
    },
    {
        Header: 'Created at',
    },
    {
        Header: 'Updated at',
    }
]

export const ACCOMMODATION_COLUMNS = [
    {
        Header: 'ID',
    },
    {
        Header: 'Title',
    },
    {
        Header: 'Items',
    },
    {
        Header: 'Destination',
    },
    {
        Header: 'Status',
    },
    {
        Header: 'Featured',
    },
    {
        Header: 'Viewed',
    },
    {
        Header: 'Created at',
    },
    {
        Header: 'Updated at',
    }
]

export const PUBLIC_COLUMNS = [
    {
        Header: 'ID',
    },
    {
        Header: 'Title',
    },
    {
        Header: 'Destination',
    },
    {
        Header: 'Viewed',
    },
    {
        Header: 'Created at',
    },
    {
        Header: 'Updated at',
    }
]

export const ADMIN_MANAGE = [
    {
        Header: "Name"
    },
    {
        Header: "Emails"
    },
    {
        Header: "Authorized"
    },
    {
        Header: "Role / Permission"
    },
]
export const HOST_MANAGE = [
    {
        Header: "ID"
    },
    {
        Header: "Host name"
    },
    {
        Header: "Business type"
    },
    {
        Header: "Joined at"
    },
    {
        Header: "Last login"
    }
]
export const USER_MANAGE = [
    {
        Header: "ID"
    },
    {
        Header: "Name"
    },
    {
        Header: "Email"
    },
    {
        Header: "Email \nverification"
    },
    {
        Header: "Marketing"
    },
    {
        Header: "Joined at"
    },
    {
        Header: "Last login"
    }
]
export const BOOKING_LOG = [
    {
        Header: "Host Name"
    },
    {
        Header: "Guest Name"
    },
    {
        Header: "Booking #"
    },
    {
        Header: "Booking Item"
    },
    {
        Header: "Booking status"
    },
    {
        Header: "Requested at"
    }
]
export const FAQ_SETTING = [
    {
        Header: "Subject"
    },
    {
        Header: "Category"
    },
    {
        Header: "Title"
    },
    {
        Header: "Updated at"
    }
]