import TableDefault from "components/HTMLTagCompo/TableDefault";
import PageContainerWrap from "components/page/PageContainerWrap";
import PageHeaderTitle from "components/page/PageHeaderTitle";
import { DESTINATION_COLUMNS } from "components/page/tableData";
import React, { useCallback, useEffect, useState } from "react";
import { requestToMustOutdoor } from "utils/requestToMustoutdoor";

const DestinationSearch = () => {
  const [destination, setDestination] = useState<any>([]);

  const fetchDestination = useCallback(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v3/destination/fetch`,
    }).then((res) => {
      const data = res.data.data;
      setDestination(data?.content);
      return data;
    });
  }, []);

  useEffect(() => {
    fetchDestination();
  }, []);

  return (
    <div>
      <PageHeaderTitle title="Destination Search" />
      <PageContainerWrap title="Destination search list">
        <TableDefault COLUMNS={DESTINATION_COLUMNS}></TableDefault>
      </PageContainerWrap>
    </div>
  );
};
export default DestinationSearch;
