import PageHeaderTitle from "components/page/PageHeaderTitle";
import React from "react";

const AtIcon = () => {
  return (
    <div>
      <PageHeaderTitle title="Icons Setting - Activity" />
    </div>
  );
};

export default AtIcon;
