import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { requestToMustOutdoor } from "utils/requestToMustoutdoor";
import FormRow from "./InputForm/FormRow";
import FormTitle from "./InputForm/FormTitle";
import PageContainerWrap from "./page/PageContainerWrap";
import PageHeaderTitle from "./page/PageHeaderTitle";

const UserInfo = () => {
  const { state } = useLocation();

  const [userInfo, setUserInfo] = useState<any>({});

  const { id, type } = state;

  const fetchUser = () => {
    if (type === "user") {
      requestToMustOutdoor({
        method: "GET",
        url: `/api/v3/users/${id}`,
      }).then((res) => {
        const data = res.data.data;
        setUserInfo(data);
        return data;
      });
    } else if (type === "host") {
      requestToMustOutdoor({
        method: "GET",
        url: `/api/v3/users/host/${id}`,
      }).then((res) => {
        const data = res.data.data;
        setUserInfo(data);
        return data;
      });
    }}


  useEffect(() => {
    fetchUser();
  }, []);

  const {
    email,
    name,
    mobileVerified,
    mobile,
    mobileCountryCode,
    marketingConsent,
    oauthType,

    totalMember,
    languageCode,
    bizType,
    pgInfo,
  } = userInfo;

  return (
    <div>
      <PageHeaderTitle
        title={
          type === "user"
            ? "User Information"
            : type === "host"
            ? "Host Information"
            : ""
        }
      />
      <PageContainerWrap
        inputWrap
        title={
          type === "user"
            ? "User information details"
            : type === "host"
            ? "Host information details"
            : ""
        }
      >
        {type === "user" ? (
          <div>
            <div className="flex items-center h-32">
              <img
                alt=""
                src={
                  "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/my-account-avatar.svg"
                }
                className="w-32 h-full"
              />
              <div className="flex flex-col flex-1 justify-between py-6 h-full ml-4">
                <div>{name}</div>
                <div>{email}</div>
              </div>
            </div>
            <FormTitle formSubTitle="Contact Number*">
              <FormRow
                readOnly
                value={mobileCountryCode}
                marginR
                input
                size="S"
              />
              <FormRow readOnly value={mobile} input size="M" />
            </FormTitle>
            <FormTitle formSubTitle="Signed Up By">
              <FormRow
                value={
                  oauthType === "KAKAO"
                    ? "Kakao"
                    : oauthType === "Google"
                    ? "Google"
                    : "Mustoutdoor"
                }
                input
                readOnly
                size="S"
              />
            </FormTitle>
            <FormTitle formSubTitle="Marketing Agreement">
              <FormRow
                value={marketingConsent ? "True" : "False"}
                input
                readOnly
                size="S"
              />
            </FormTitle>
            <FormTitle formSubTitle="Active Status">
              <FormRow readOnly input size="S" />
            </FormTitle>
          </div>
        ) : type === "host" ? (
          <div>
            <div className="flex items-center h-32">
              <img
                alt=""
                src={
                  "https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/my-account-avatar.svg"
                }
                className="w-32 h-full"
              />
              <div className="flex flex-col flex-1 justify-between py-6 h-full ml-4">
                <div>{name}</div>
                <div>{totalMember?.email}</div>
              </div>
            </div>
            <FormTitle formSubTitle="Contact Number*">
              <FormRow
                readOnly
                value={totalMember?.mobileCountryCode}
                marginR
                input
                size="S"
              />
              <FormRow readOnly value={totalMember?.mobile} input size="M" />
            </FormTitle>
            <FormTitle formSubTitle="Preferred Language">
              <FormRow
                value={languageCode && languageCode[0]}
                input
                readOnly
                size="S"
              />
            </FormTitle>
            <hr className="my-8" />

            <div className="flex items-center">
              <div className="text-xl mr-8 font-bold">Business details</div>
              <FormRow
                select
                placeholder="select"
                value={bizType && bizType[0]}
                options={["PG", "AC", "AT"]}
                size="S"
              />
            </div>
            <FormTitle formSubTitle="Telephone Number*">
              <FormRow
                readOnly
                value={pgInfo?.countryCode}
                marginR
                input
                size="S"
              />
              <FormRow readOnly value={pgInfo?.telephone} input size="M" />
            </FormTitle>
            <FormTitle formSubTitle="Ownership Certificate">
              <FormRow
                value={
                  pgInfo?.bizType
                    ? "Ownership Certificate as Business Registration"
                    : "Ownership Certificate of Private Property(Ground)"
                }
                input
                readOnly
                size="L"
              />
            </FormTitle>
            {pgInfo?.bizType === false ? (
              <div>
                <div className="mb-2">Lease or Rental Contract of a land</div>
                <FormRow input readOnly value={pgInfo?.groundUri} size="L" />
              </div>
            ) : (
              <div>
                <div className="mb-2">Business Registration Number*</div>
                <FormRow readOnly value={pgInfo?.bizNumber} input size="L" />
                <div className="mb-2 mt-6">
                  Business Registration Certificate*
                </div>
                <FormRow readOnly value={pgInfo?.bizNumber} input size="L" />
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </PageContainerWrap>
    </div>
  );
};

export default UserInfo;