import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ChangeDate } from "utils/changeDate";
import { requestToMustOutdoor } from "utils/requestToMustoutdoor";
import ButtonDefault from "./HTMLTagCompo/ButtonDefault";
import FormRow from "./InputForm/FormRow";
import FormTitle from "./InputForm/FormTitle";
import PageContainerWrap from "./page/PageContainerWrap";
import PageHeaderTitle from "./page/PageHeaderTitle";

const ItemDetail = () => {
  const navigate = useNavigate();
  const [itemData, setItemData] = useState<any>([]);
  const [destinationList, setDestination] = useState<any>([]);

  const [statusValue, setStatusValue] = useState("");
  const [destinationValue, setDestinationValue] = useState("");
  const [featuredValue, setFeaturedValue] = useState<boolean>();
  const [mustPickValue, setMustPickValue] = useState<boolean>();
  const [hotDealValue, setHotDealValue] = useState<boolean>();

  const [desinationId, setDestinationId] = useState<number>();

  const { state } = useLocation();
  const { userId } = JSON.parse(localStorage.getItem("user") as any);

  const type = state.type;
  const id = state.itemNum;

  const fetchItem = useCallback(() => {
    if (type === "PG") {
      requestToMustOutdoor({
        method: "GET",
        url: `/api/v3/private-ground/${id}`,
      }).then((res) => {
        const data = res.data.data;
        const destinationId = data?.destinationInfo.id;
        setDestinationId(destinationId);
        setItemData(data);
        return data;
      });
    } else {
      requestToMustOutdoor({
        method: "GET",
        url: `/api/v3/accommodation/${id}`,
      }).then((res) => {
        const data = res.data.data;
        const destinationId = data?.destinationInfo.id;
        setDestinationId(destinationId);
        setItemData(data);
        return data;
      });
    }
  }, [id]);
  const fetchDestination = useCallback(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/guest/main/choice/destination`,
    }).then((res) => {
      const data = res.data.data;
      setDestination(data);
      return data;
    });
  }, []);

  useEffect(() => {
    fetchItem();
    fetchDestination();
  }, []);

  const cityList = destinationList.map((item: any) => {
    return item.city;
  });

  useEffect(() => {
    destinationList.map((item: any) => {
      const city = item?.city;
      const id = item?.id;
      if (city === destinationValue) {
        setDestinationId(id);
        return id;
      }
    });
  }, [destinationValue]);

  const {
    title,
    hostInfo,
    locationThemeList,
    themeList,
    countryCode,
    country,
    city,
    spaceList,
    policyInfo,
    views,
    view,
    updatedAt,
    createdAt,
    status,
    currentImage,
    typeInfo,
    unitCount,
    accessibleVehicle,
    spaceSize,
    hotDeal,
    mustPick,
    featured,
    destinationInfo,
    address,
  } = itemData;

  console.log(itemData);

  useEffect(() => {
    setStatusValue(status);
    setFeaturedValue(featured);
    setMustPickValue(mustPick);
    setHotDealValue(hotDeal);
  }, [featured, hotDeal, mustPick, status]);

  const PGTheme = locationThemeList?.map((item: any) => {
    const listInfo = item?.themeInfoList;
    return listInfo?.enName;
  });
  const ACTheme = themeList?.map((item: any) => {
    const listInfo = item?.themeInfoList;
    return listInfo?.enName;
  });

  const pgData = {
    destinationId: desinationId,
    hotDeal: hotDealValue,
    message: "",
    mustPick: mustPickValue,
    status: statusValue,
    totalMemberId: Number(userId),
  };
  const acData = {
    destinationId: desinationId,
    hotDeal: hotDealValue,
    message: "",
    mustPick: mustPickValue,
    status: statusValue,
    totalMemberId: Number(userId),
  };

  const changePgData = () => {
    if (type === "PG") {
      requestToMustOutdoor({
        method: "PATCH",
        url: `/api/v3/private-ground/status/${id}`,
        data: { ...pgData },
      }).then((res) => {
        const data = res.data.data;
        navigate(-1);
        return data;
      });
    } else {
      requestToMustOutdoor({
        method: "PATCH",
        url: `/api/v3/accommodation/${id}`,
        data: { ...acData },
      }).then((res) => {
        const data = res.data.data;
        navigate(-1);
        return data;
      });
    }
  };
  
  // PENDING 선택 불가능하게 하기

  const statusOption = () => {
    if (status === "PENDING")
      return ["PENDING", "APPROVED", "DENIED", "REMOVED"];
    else return ["APPROVED", "DENIED", "REMOVED"];
  };

  return (
    <div>
      <PageHeaderTitle title="Listing Information" />
      <PageContainerWrap title="Listing information details">
        <FormTitle rowForm formTitle="Title">
          <FormRow readOnly input size="L" value={title || ""} />
        </FormTitle>
        <FormTitle rowForm formTitle="Host Name">
          <FormRow readOnly input size="L" value={hostInfo?.name || ""} />
        </FormTitle>
        <FormTitle rowForm formTitle="Business Type">
          <FormRow
            readOnly
            input
            size="L"
            value={type === "PG" ? "Private Ground" : "Accommodation"}
          />
        </FormTitle>
        <FormTitle rowForm formTitle="Location Theme">
          <FormRow
            readOnly
            input
            size="L"
            value={type === "PG" ? PGTheme : type === "AC" ? ACTheme : ""}
          />
        </FormTitle>
        <FormTitle rowForm formTitle="Address">
          <FormRow
            readOnly
            input
            marginR
            size="L"
            value={
              type === "PG"
                ? countryCode +
                  "" +
                  itemData?.state +
                  " " +
                  city +
                  " " +
                  address
                : type === "AC"
                ? country + " " + itemData?.state + " " + city + " " + address
                : ""
            }
          />
        </FormTitle>
        <FormTitle rowForm formTitle="Search Destination">
          <FormRow
            value={destinationValue ? destinationValue : destinationInfo?.city}
            options={cityList}
            onChange={(e) => {
              setDestinationValue(e.target.value);
            }}
            select
            size="S"
            placeholder="Select"
          />
        </FormTitle>
        <FormTitle rowForm formTitle="Cancellation & Refund Policy">
          <FormRow
            input
            readOnly
            size="L"
            value={(policyInfo?.name || "") + " Strict Cancellation Policy"}
          />
        </FormTitle>
        <FormTitle rowForm formTitle="Admin Status">
          <FormRow
            select
            marginR
            size="S"
            onChange={(e) => {
              setStatusValue(e.target.value);
            }}
            placeholder="Select"
            options={statusOption()}
            value={statusValue}
          />
        </FormTitle>
        <FormTitle rowForm formTitle="Featured Option">
          <input
            checked={featuredValue}
            onChange={(e) => {
              setFeaturedValue(e.target.checked);
            }}
            className="mr-2"
            type={"checkBox"}
          />
          <div>Featured Mark</div>
        </FormTitle>
        <FormTitle rowForm formTitle="Must Pick Option">
          <input
            checked={mustPickValue}
            onChange={(e) => {
              setMustPickValue(e.target.checked);
            }}
            className="mr-2"
            type={"checkBox"}
          />
          <div>Display in Must Pick</div>
        </FormTitle>
        <FormTitle rowForm formTitle="Hot Deal Option">
          <input
            checked={hotDealValue}
            onChange={(e) => {
              setHotDealValue(e.target.checked);
            }}
            className="mr-2"
            type={"checkBox"}
          />
          <div>Display in Hot Deal</div>
        </FormTitle>
        <FormTitle rowForm formTitle="Page View Count">
          <FormRow
            readOnly
            input
            size="S"
            value={type === "PG" ? views : type === "AC" ? view : "" || ""}
          />
        </FormTitle>
        <FormTitle rowForm formTitle="Created At">
          <FormRow
            readOnly
            input
            size="S"
            value={ChangeDate(createdAt || "")}
          />
        </FormTitle>
        <FormTitle rowForm formTitle="Updated At">
          <FormRow
            readOnly
            input
            size="S"
            value={ChangeDate(updatedAt || "")}
          />
        </FormTitle>
        {type === "AC" ? (
          <FormTitle rowForm formTitle="Belonged Items">
            <FormRow readOnly input size="S" value={spaceList?.length} />
          </FormTitle>
        ) : (
          ""
        )}
        <div>
          <div className="flex justify-between items-center">
            <div className="text-xl font-bold">Belonged items details</div>
            <div>Item information</div>
            <div>Updated At</div>
          </div>
          <div className="flex w-full justify-between items-center">
            <div className="w-[800px] h-40 rounded-lg border border-input-border-color my-8 flex">
              <img
                className="w-[276px] rounded-l-lg h-full"
                src={currentImage?.url}
                alt=""
              />
              <div className="p-2 flex-1 max-w-[382px] flex flex-col justify-between">
                <div>{title}</div>
                <div>
                  <div className="flex items-center">
                    <img
                      className="w-5 h-5 mr-2"
                      src={typeInfo?.iconUrl}
                      alt=""
                    />
                    <div>{typeInfo?.enName}</div>
                  </div>
                  <div className="flex items-center my-4">
                    <div className="flex items-center mr-4">
                      <img
                        className="w-5 h-5 mr-2"
                        src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/available.svg"
                        alt=""
                      />
                      <div>X {unitCount}</div>
                    </div>
                    <div className="flex items-center">
                      <img
                        className="w-5 h-5 mr-2"
                        src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/accessible-vehicle.svg"
                        alt=""
                      />
                      <div>{accessibleVehicle}</div>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <img
                      className="w-5 h-5 mr-2"
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/space-size.svg"
                      alt=""
                    />
                    <div>{spaceSize}㎡</div>
                  </div>
                </div>
              </div>
              <div className="border-l border-input-border-color"></div>
            </div>
            <div className="max-w-[92px]">{ChangeDate(updatedAt)}</div>
          </div>
        </div>
        <div className="flex justify-between">
          <ButtonDefault
            onClick={() => changePgData()}
            className="inline-block bg-tan"
            title="Save Listing Information"
          />
          <ButtonDefault
            className="text-black border border-pure-red"
            onClick={() => {
              navigate(-1);
            }}
            title="Cancel"
          />
        </div>
      </PageContainerWrap>
    </div>
  );
};

export default ItemDetail;
