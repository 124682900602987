import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { requestToMustOutdoor } from "utils/requestToMustoutdoor";
import ButtonDefault from "./HTMLTagCompo/ButtonDefault";
import CheckBoxDefault from "./HTMLTagCompo/CheckBoxDefault";
import FormRow from "./InputForm/FormRow";
import FormTitle from "./InputForm/FormTitle";

import PageContainerWrap from "./page/PageContainerWrap";
import PageHeaderTitle from "./page/PageHeaderTitle";

const CreateIcons = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [iconName, setIconName] = useState<string>("");
  const [iconUrl, setIconurl] = useState<string>("");
  const [featured, setFeatured] = useState<boolean>();

  const { type } = state;

  const typeData = {
    category: "string",
    description: description,
    featured: featured,
    iconFileName: "string",
    iconUrl: "string",
    imageUrl: "string",
    listingType: "AC",
    name: title,
    totalMemberId: 0,
  };

  const themeData = {
    description: description,
    featured: featured,
    iconFileName: "string",
    iconUrl: "string",
    imageUrl: "string",
    name: title,
    totalMemberId: 0,
  };

  const registIcon = () => {
    requestToMustOutdoor({
      method: "POST",
      url:
        type === "TYPE"
          ? "/api/v3/type/register"
          : type === "THEME"
          ? "/api/v3/theme/register"
          : "",
      data: type === "TYPE" ? typeData : type === "THEME" ? themeData : "",
    }).then((res) => {
      const data = res.data.data;
      return data;
    });
  };

  const handleFileUpload = (e: any) => {
    let files = e.target.files[0];
    const fileSize = files?.size;
    const data = {
      multipartFile: files,
    };

    requestToMustOutdoor({
      method: "POST",
      url: "/guest/upload/file",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
      data,
    }).then((res) => {
      const data = res.data.data;
      const imgUrl = data[0];
      const imgUrl_ = `${process.env.REACT_APP_IMAGE_DEV_UPLOAD_URL}${imgUrl}`;
      setIconurl(imgUrl_)
      return data;
    });
  };

  console.log(iconUrl)

  const fileInput = useRef<any>();

  const handleButtonClick = () => {
    fileInput?.current.click();
  };

  return (
    <div>
      <PageHeaderTitle
        title={type === "TYPE" ? "Types Manage" : "Themes Manage"}
      ></PageHeaderTitle>
      <PageContainerWrap
        title={type === "TYPE" ? "Create Types" : "Create Themes"}
      >
        <FormTitle rowForm formTitle="Title*">
          <FormRow
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            input
            size="L"
          />
        </FormTitle>
        <FormTitle rowForm formTitle="Description*">
          <FormRow
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            input
            size="L"
          />
        </FormTitle>
        <FormTitle rowForm formTitle="Icon*">
          <div className="rounded-lg p-4 h-24 w-[640px] border border-input-border-color">
            <input
              onChange={(e) => {
                handleFileUpload(e);
              }}
              ref={fileInput}
              type="file"
              className="hidden"
            />
            <div
              onClick={() => {
                handleButtonClick();
              }}
            >
              <img
                className="w-16 h-16 cursor-pointer"
                alt=""
                src={iconUrl ? iconUrl : `https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/gallery.svg`}
              />
            </div>
          </div>
        </FormTitle>
        <FormTitle rowForm formTitle="Feature Image*">
          <div
            className="border border-input-border-color w-96 h-96 cursor-pointer"
            // src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/gallery.svg"
          >
            이미지 업로드
          </div>
        </FormTitle>
        <FormTitle rowForm formTitle="Featured Option">
          <CheckBoxDefault
            onChange={(e) => {
              setFeatured(e.target.checked);
            }}
            title="Featured on main theme"
          />
        </FormTitle>
        <div className="flex justify-between items-center">
          <ButtonDefault title="Save" className="bg-light-green"></ButtonDefault>
          <ButtonDefault title="Cancel" className="text-pure-red border border-pure-red"></ButtonDefault>
        </div>
      </PageContainerWrap>
    </div>
  );
};

export default CreateIcons;
