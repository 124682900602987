import TableDefault from "components/HTMLTagCompo/TableDefault";
import PageContainerWrap from "components/page/PageContainerWrap";
import PageHeaderTitle from "components/page/PageHeaderTitle";
import { ACCOMMODATION_COLUMNS } from "components/page/tableData";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChangeDate } from "utils/changeDate";
import { requestToMustOutdoor } from "utils/requestToMustoutdoor";

const AccommodationListing = () => {
  const navigate = useNavigate();
  const [accommodationData, setAccommodationData] = useState([]);

  const [total, setTotal] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v3/accommodation`,
      params: { title: "", page: currentPage, size: 10, sort: "id" },
    })
      .then((res) => {
        const data = res.data.data;
        const totalElements = data.totalElements;
        const totalPages = data.totalPages;
        const size = data.size;
        setTotal(totalPages);
        setLimit(size);
        setAccommodationData(data?.content);
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentPage]);

  return (
    <div>
      <PageHeaderTitle title="Listings - Accommodation" />
      <PageContainerWrap title="Listings">
        <TableDefault COLUMNS={ACCOMMODATION_COLUMNS}>
          {accommodationData?.map((item: any, idx: number) => {
            const id = item?.id;
            const title = item?.title;
            const state = item.state;
            const status = item.status;
            const featured = String(item.featured);
            const views = item.view;
            const itemLength = item.spaceList?.length;
            const createdAt = ChangeDate(item.createdAt);
            const updatedAt = ChangeDate(item.updatedAt);
            return (
              <tr key={idx}>
                <td>{id}</td>
                <td>{title}</td>
                <td>{itemLength}</td>
                <td>{state}</td>
                <td>{status}</td>
                <td>{featured}</td>
                <td>{views}</td>
                <td>{createdAt}</td>
                <td>{updatedAt}</td>
                <td>
                  <div className="min-w-[63px]">
                    <img
                      onClick={() => {
                        navigate(`/detail/${id}`, {
                          state: { type: "AC", itemNum: id },
                        });
                      }}
                      className="w-5 h-5 cursor-pointer mx-auto"
                      alt=""
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/edit-user.svg"
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </TableDefault>
      </PageContainerWrap>
    </div>
  );
};

export default AccommodationListing;
