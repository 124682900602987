import TableDefault from "components/HTMLTagCompo/TableDefault";
import PageContainerWrap from "components/page/PageContainerWrap";
import PageHeaderTitle from "components/page/PageHeaderTitle";
import { ADMIN_MANAGE } from "components/page/tableData";
import React, { useCallback, useEffect, useState } from "react";
import { requestToMustOutdoor } from "utils/requestToMustoutdoor";

const ManageAdmin = () => {
  const [adminData, setAdminData] = useState([]);

  const { adminId } = JSON.parse(localStorage.getItem("user") as any);
  const adminR = localStorage.getItem("adminRole");

  const fetchAdminManage = useCallback(() => {
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v3/admin/fetch`,
      data: { hostName: "", page: "", size: "", sort: "" },
    })
      .then((res) => {
        const data = res.data.data;
        setAdminData(data);
        return data;
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    fetchAdminManage();
  }, []);

  return (
    <div>
      <PageHeaderTitle title="Manage Admin Users" />
      <PageContainerWrap title="Admin Users">
        <TableDefault COLUMNS={ADMIN_MANAGE}>
          {adminData?.map((item: any, idx) => {
            const id = item.id;
            const name = item.name;
            const email = item.email;
            const authorized = String(item.authorized);
            const adminRole = item.adminRole;
            return (
              <tr key={idx}>
                <td
                  className={id === Number(adminId) ? "text-light-green" : ""}
                >
                  {name}
                </td>
                <td>{email}</td>
                <td>{authorized}</td>
                <td>{adminRole}</td>
                <td>
                  <div className="flex min-w-[63px] items-center justify-between">
                    <img
                      className="w-5 h-5 cursor-pointer"
                      alt=""
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/edit-user.svg"
                    />
                    /
                    <img
                      onClick={() => {
                        if (adminR === "SUPER_ADMIN") {
                          if (window.confirm("정말 삭제할까요 ?")) {
                            requestToMustOutdoor({
                              method: "DELETE",
                              url: `/api/v3/admin/${id}`,
                            }).then((res) => {
                              const data = res.data.data;
                              alert("삭제됨");
                              return data;
                            });
                          } else {
                            return;
                          }
                        } else if (Number(adminId) === id) {
                          if (window.confirm("정말 삭제할까요 ?")) {
                            requestToMustOutdoor({
                              method: "DELETE",
                              url: `/api/v3/admin/${id}`,
                            }).then((res) => {
                              const data = res.data.data;
                              alert("삭제됨");
                              return data;
                            });
                          } else {
                            return;
                          }
                        }
                      }}
                      className="w-5 h-5 cursor-pointer"
                      alt=""
                      src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/delete-trash.svg"
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </TableDefault>
      </PageContainerWrap>
    </div>
  );
};

export default ManageAdmin;
