import TableDefault from "components/HTMLTagCompo/TableDefault";
import PageContainerWrap from "components/page/PageContainerWrap";
import PageHeaderTitle from "components/page/PageHeaderTitle";
import { NOTIFICATION_COLUMNS } from "components/page/tableData";
import React from "react";

const ManualNotification = () => {
  return (
    <div>
      <PageHeaderTitle title="Notification Send Setting" />
      <PageContainerWrap title="Notification Send lists">
        <TableDefault COLUMNS={NOTIFICATION_COLUMNS}></TableDefault>
      </PageContainerWrap>
    </div>
  );
};

export default ManualNotification;
