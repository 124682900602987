/* global kakao */

import React, { useEffect, useState } from "react";

declare global {
  interface Window {
    kakao: any;
  }
}

const KakaoMap = ({ AddInfo }: any) => {
  const { kakao }: any = window;
  useEffect(() => {
    const Lat = AddInfo?.lat !== 0 ? AddInfo?.lat : 37.496486063;
    const Lng = AddInfo?.lng !== 0 ? AddInfo?.lng : 127.028361548;
    window.kakao.maps.load(() => {
      const mapContainer = document.getElementById("map");
      const mapOption = {
        center: new window.kakao.maps.LatLng(Lat, Lng), // 지도의 중심좌표
        level: 4, // 지도의 확대 레벨
      };
      const map = new window.kakao.maps.Map(mapContainer, mapOption);
      map.relayout();
      let markerPosition = new window.kakao.maps.LatLng(
        AddInfo?.lat,
        AddInfo?.lng
      );

      let marker = new window.kakao.maps.Marker({
        position: markerPosition,
      });
      marker.setMap(map);
      map.setZoomable(false);
    });
  }, [AddInfo]);

  return (
    <div className="w-[640px] h-80">
      <div id="map" style={{ width: "100%", height: "100%" }} />
    </div>
  );
};

export default KakaoMap;
