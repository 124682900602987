import TableDefault from "components/HTMLTagCompo/TableDefault";
import PageContainerWrap from "components/page/PageContainerWrap";
import PageHeaderTitle from "components/page/PageHeaderTitle";
import { PAYOUT_COLUMNS } from "components/page/tableData";
import React from "react";

const Payout = () => {
  return (
    <div>
      <PageHeaderTitle title="Payment Log" />
      <PageContainerWrap title="Payment Log Information">
        <TableDefault COLUMNS={PAYOUT_COLUMNS}></TableDefault>
      </PageContainerWrap>
    </div>
  );
};

export default Payout;
