import React from 'react';

const DashBoard = () => {
    return (
        <div>
            대시보드
        </div>
    );
};

export default DashBoard;