import axios, { AxiosInstance } from 'axios';

// const RT = localStorage.getItem('RT')

// export const requestToMustOutdoor: AxiosInstance = axios.create({
//     baseURL: `${process.env.REACT_APP_API_URL}`,
//     headers: {
//         authorization: RT ? `Bearer ${RT}` : undefined,
//     }
// })


// export const requestToMustOutdoor = async ({ method, path, accessToken = undefined, data = undefined }: any) => {
//     try {
//         const res = await axios({
//             method,
//             url: `http://dev-server.mustoutdoor.com` + `${path}`,
//             headers: {
//                 authorization: accessToken ? accessToken : undefined
//             },
//             data,
//         });
//         console.log(res);
//         return res;
//     } catch (error: any) {
//         console.log(error.response);
//         return;

//     }
// }

    export const requestToMustOutdoor: AxiosInstance = axios.create({
        baseURL: `${process.env.REACT_APP_API_URL || `http://dev-server.mustoutdoor.com`}`, 
    })

    requestToMustOutdoor.interceptors.request.use(function (config:any) {
        const token = localStorage.getItem('refresh');
        config.headers.Authorization = token !== null ? "bearer " + token : ''
      
        return config;
      });
