import React from 'react';
import HeaderLogo from 'assets/Logo/must-logo-color.png'
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate();
    return (
        <div className='flex flex-row w-full'>
            <div className='border w-64 box-border px-5 py-5 border-light-green'>
                <img onClick={() => {
                    navigate('/')
                }} className='cursor-pointer w-60 h-11 items-center mx-auto ' src={HeaderLogo} alt="HeaderLogo" />
            </div> 
            <div className='flex grow box-border justify-around basis-2/4 items-center py-5 bg-light-green text-pure-white text-center'>
                <div></div>
                <div className='text-xl font-bold'>
                    “Make you reach every single outdoor life” through MUST OUTDOOR
                </div>
                <div className='text-sm hover:text-black cursor-pointer'>
                    My Account
                </div>
            </div> 
        </div>
    );
};

export default Header;