import React from 'react';

interface FormTitleProps {
    rowForm?:boolean;
    formTitle?: string;
    formSubTitle?: string;
    children?: any;
    col?:boolean
}

const FormTitle = ({rowForm, formTitle, formSubTitle, children, col}:FormTitleProps) => {
    return (   
        <div className={`flex ${rowForm ? 'flex-row' : "flex-col"} my-8`}>
        <div className={` ${rowForm ? "w-[270px]" : "mb-4 font-bold"}`}>{formTitle}</div>
        {formSubTitle && !rowForm ? 
        <div className='mb-2'>
            {formSubTitle}
        </div> : "" }
        <div className={`flex ${col ? "flex-col" : "flex-row"} items-center`}>
            {children}
        </div>
        </div>
    );
};

export default FormTitle;