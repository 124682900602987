import React from 'react';

const EmailTemplate = () => {
    return (
        <div>
            Email Template
        </div>
    );
};

export default EmailTemplate;