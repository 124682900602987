import React, { useEffect, useState } from "react";

import TableDefault from "components/HTMLTagCompo/TableDefault";
import PageContainerWrap from "components/page/PageContainerWrap";
import PageHeaderTitle from "components/page/PageHeaderTitle";
import { BOOKING_LOG } from "components/page/tableData";

import { ChangeDate } from "utils/changeDate";
import { requestToMustOutdoor } from "utils/requestToMustoutdoor";
import { useNavigate } from "react-router-dom";
import Pagenation from "components/Pagenation";

const BookingLog = () => {
  const navigate = useNavigate();
  const [bookingData, setBookingData] = useState([]);

  const [total, setTotal] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState(0);

  const fetchBooking = () => {
    // request_at으로 수정하면 최신순으로 변경
    requestToMustOutdoor({
      method: "GET",
      url: `/api/v3/reservation/fetch`,
      params: {
        hostName: "",
        resoCode: "",
        travelerName: "",
        page: currentPage,
        size: 10,
        sort: "id",
      },
    }).then((res) => {
      const data = res.data.data;
      const totalPages = data.totalPages;
      const size = data.size;
      setTotal(totalPages);
      setLimit(size);
      setBookingData(data?.content);
      return data;
    });
  };

  useEffect(() => {
    fetchBooking();
  }, [currentPage]);
  return (
    <div>
      <PageHeaderTitle title="Booking Log" />
      <PageContainerWrap title="Booking logs information">
        <TableDefault COLUMNS={BOOKING_LOG}>
          {bookingData?.map((item: any, idx) => {
            const hostName = String(item?.hostName);
            const travelerName = String(item?.travelerName);
            const resoCode = item?.resoCode;
            const pgTitle = item?.pgTitle;
            const status = item?.status;
            const pgId = item?.pgId;
            const id = item?.id;
            const createAt = ChangeDate(item?.createAt);
            const updateAt = ChangeDate(item?.updateAt);
            return (
              <tr key={idx}>
                <td>{hostName}</td>
                <td>{travelerName}</td>
                <td>{resoCode}</td>
                <td>{pgTitle}</td>
                <td>{status}</td>
                <td>{createAt}</td>
                <td>
                  <img
                    onClick={() => {
                      navigate("/booking/details", {
                        state: {
                          reservationId: id,
                          pgId: pgId,
                          createAt: createAt,
                          updateAt: updateAt,
                        },
                      });
                    }}
                    className="w-5 h-5 cursor-pointer mx-auto"
                    alt=""
                    src="https://mustoutdoor-dev.s3.ap-northeast-2.amazonaws.com/admin/dev/v1/common/edit-user.svg"
                  />
                </td>
              </tr>
            );
          })}
        </TableDefault>
        <Pagenation
          total={total}
          currenct={currentPage}
          setPage={setCurrentPage}
        />
      </PageContainerWrap>
    </div>
  );
};

export default BookingLog;
